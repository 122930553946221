import React, { memo, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';

import { Button, MobileStepper, Box } from '@mui/material';



const Home = memo(() => {
    const { t } = useTranslation();
    const { accessToken } = useSelector(state => state.general);

    const steps = [
        {
            label: t('tutorial.fundInTitle-1'),
            imgPath: '/images/tutorial/fund_in/step1.png',
            description: t('tutorial.fundInDesc-1'),
        },
        {
            label: t('tutorial.fundInTitle-2'),
            imgPath: '/images/tutorial/fund_in/step2.png',
            description: t('tutorial.fundInDesc-2'),
        },
        {
            label: t('tutorial.fundInTitle-3'),
            imgPath: '/images/tutorial/fund_in/step3.png',
            description: t('tutorial.fundInDesc-3'),
        },
        {
            label: t('tutorial.fundInTitle-4'),
            imgPath: `/images/tutorial/fund_in/step4.png`,
            description: t('tutorial.fundInDesc-4'),
        },
        {
            label: t('tutorial.fundInTitle-5'),
            imgPath: '/images/tutorial/fund_in/step5.png',
            description: t('tutorial.fundInDesc-5'),
        },
        {
            label: t('tutorial.fundInTitle-6'),
            imgPath: '/images/tutorial/fund_in/step6.png',
            description: t('tutorial.fundInDesc-6'),
        },
        {
            label: t('tutorial.fundInTitle-7'),
            imgPath: '/images/tutorial/fund_in/step7.png',
            description: t('tutorial.fundInDesc-7'),
        },
        {
            label: t('tutorial.fundInTitle-8'),
            imgPath: '/images/tutorial/fund_in/step8.png',
            description: t('tutorial.fundInDesc-8'),
        },
        {
            label: t('tutorial.fundInTitle-9'),
            imgPath: '/images/tutorial/fund_in/step9.png',
            description: t('tutorial.fundInDesc-9'),
        },
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = steps.length;

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


   
    return (
        <div className='p-lr-15 app-header-margin of-hidden'>
            <div className='clwhite txt-center txt-title'>
                <p className='lh-12'>
                    <b>{t('tutorial.howToMake')}<br/>
                    {t('tutorial.fundIn')}</b>
                </p>
            </div>
            <Box className="clwhite pos-relative " sx={{ maxWidth: 400, flexGrow: 1 }}>
                <div className='p-lr-20'>
                    <img src={steps[activeStep].imgPath} alt={t('title.tutorial')}/>
                </div>
                <div className='p-t-10 txt-center'>
                    <p className='p-b-15 fs-16 txt-title'><b>{steps[activeStep].label}</b></p>
                    <p>{steps[activeStep].description}</p>
                </div>

                <div style={{position:'relative'}}>
                <MobileStepper
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    style={{ background: '#ffffff1c', borderRadius:10, color: '#fff', position:'fixed', bottom:20, width:414, maxWidth:'calc(100vw - 30px)', backdropFilter: 'blur(8px)' }}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            style={{ width: 'unset', minWidth: 95, padding: 6 }}
                            disabled={activeStep === maxSteps - 1}
                        >
                            {t('button.nextStep')}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0} style={{ width: 'unset', padding: '3px 5px' }}>             
                            {t('button.backStep')}
                        </Button>
                    }
                />
                </div>
            </Box>
        </div>
    )
});


export default Home;