import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { Avatar, Box, Card, CardContent, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BackIcon from '@mui/icons-material/ReplyAll';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import theme from '../../Theme';

export default function GenealogySponsor(props) {
    const { t } = useTranslation();
    const { id } = useSelector(state => state.user);
    const styles = useStyles();
    const [tree, setTree] = useState([]);
    const [expandMore, setExpandMore] = useState({});
    const [state, setState] = useState({ totalDirectDownlines: 0, totalDownlines: 0, username: '' });
  
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();

    useEffect(() => {
        isMountedRef.current = true;
        setTree(props.state.data);
        setState({ ...state, username: props.state.data.username });
        let expandStateArr = {};
        expandStateArr[props.state.data.id] = { id: props.state.data.id, expand: true };
        _.map(props.state.data.children, node => (
            expandStateArr[node.id] = { id: node.id, expand: false }
        ))
        setExpandMore(expandStateArr);
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [props.state])

    const searchUsername = (username) => {
        setLoading(true);
        getUrl(`/sponsor-genealogy`, { username: username ? username : props.state.data.username }).then(response => {
            if (isMountedRef.current) {
                let { status, message, error, data } = response;
                if (status) {
                    setTree(data.tree_data);
                    let expandStateArr = {};
                    if (props.state.data.username) {
                        expandStateArr[data.tree_data.id] = { id: data.tree_data.id, expand: true };
                        _.map(data.tree_data.children, node => (
                            expandStateArr[node.id] = { id: node.id, expand: false }
                        ))
                    } else {
                        _.map(data.tree_data.children, node => (
                            expandStateArr[node.id] = { id: node.id, expand: false }
                        ))
                    }
                    setExpandMore(expandStateArr);
                } else {
                    if (error || message) {
                        if (_.size(error) > 0) {
                            _.map(error, (value, key) => {
                                message += "\n " + value[0];
                            })
                        }
                        addAlert(message);
                    }
                }
            }
            setLoading(false);
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }
        });
    }

    const viewMore = (value) => {
        searchUsername(value);
    }

    const handleExpand = (id) => {
        setExpandMore({ ...expandMore, [id]: { ...expandMore.id, expand: !expandMore[id].expand } });
    }

    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={`${nodes.id}`}
            className={styles.downlineBoxDesign}
            onClick={() => handleExpand(nodes.id)}
            disabled={_.size(nodes.children) > 0 ? false : true}
            id="sponsor"
            label={
                <div className='dark-blue-bg' >
                    <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item xs={9} sm={7}>
                            <Grid container>
                                <Grid item xs={4} sm={4} >
                                    <Avatar sx={{ width: 50, height: 50 }}>{nodes.username.charAt(0).toUpperCase()}</Avatar>
                                </Grid>
                                <Grid item xs={8} sm={8} >
                                    <Typography variant='body1' className='txt-truncate txt-upper' style={{ color: '#FFF' }}><b>{nodes.username}</b></Typography>
                                    {/* <Typography variant='body1' className='txt-truncate ' style={{ color: '#667085' }}>Total Package: 0</Typography> */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} sm={5} style={{ paddingRight: 25 }}>
                            <Grid container >
                                <Grid item xs={10} className='flex-b' style={{ textAlign: 'end', justifyContent: 'flex-end' }} >
                                    {/* <Typography variant='body1' className='txt-truncate txt-upper' style={{ color: theme.palette.primary.main }}><b>$ 0.00</b></Typography> */}
                                    <Typography variant='body1' style={{ color: '#667085', textAlign: "end", fontWeight: 500, fontSize: 14 }}>{moment(nodes.user_created_at).format('DD/MM/YYYY')}</Typography>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: 'end' }} >
                                    {
                                        expandMore?.[nodes.id]?.expand ?
                                            <IconButton disabled>
                                                <KeyboardArrowUpIcon color='primary' fontSize='large' />
                                            </IconButton>
                                            : <IconButton disabled>
                                                <KeyboardArrowDownIcon color='primary' fontSize='large' />
                                            </IconButton>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            }
        >
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderSubTree(node)) : null}
        </TreeItem>
    );

    const renderSubTree = (nodes) => (
        <div key={nodes.id}>
            <Divider sx={{ bgcolor: '#1b2755', marginTop: 2, borderBottomWidth: 2 }} />
            <Grid container style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                <Grid item xs={12}>
                    <p className={styles.subline}>{t('general.member')}</p>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container>
                        <Grid item xs={12} sm={12} >
                            <p className={styles.downlineMemberUsername}>{nodes.username}</p>
                            {/* <Typography variant='body1' className='txt-truncate ' style={{ color: '#667085' }}>Total Package: 0</Typography> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8} style={{ paddingRight: 5 }}>
                    <Grid container >
                        <Grid item xs={10} style={{ display: 'flex', textAlign: 'end', justifyContent: 'flex-end', alignItems: 'center' }} >
                            {/* <Typography variant='body1' className='txt-truncate txt-upper' style={{ color: theme.palette.primary.main }}><b>$ 0.00</b></Typography> */}
                            <Typography variant='body1' className='txt-truncate ' style={{ color: '#667085', fontSize: 14, fontWeight: 500 }}>{t('genealogy.dateJoined', { 'date': moment(nodes.user_created_at).format('DD/MM/YYYY') })}</Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'end' }} >
                            {
                                nodes.total_downline > 0 ?
                                    <IconButton onClick={() => viewMore(nodes.username)}>
                                        <KeyboardArrowDownIcon color='primary' fontSize='large' />
                                    </IconButton>
                                    : null
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )

    return (
        <div>
            {
                _.size(tree?.children) > 0 ?
                    <Card style={{ border: 'none', marginBottom: 130, background: 'transparent' }} variant="outlined">
                        <CardContent style={{ borderRadius: 0, padding: 0 }}>
                            <Grid container >
                                <Grid item xs={12} style={{ overflowX: "auto", padding: '10px 0 4px' }}>
                                    <Tooltip title={t('genealogy.back')} arrow>
                                        <IconButton onClick={() => searchUsername()}>
                                            <BackIcon color='primary' className='m-l-15' />
                                        </IconButton>
                                    </Tooltip>
                                    {/* <Link underline="hover" color="inherit"  onClick={() => searchUsername(state.username)} className={styles.subline2}>{t('genealogy.back')}</Link> */}
                                </Grid>
                                <Grid item xs={12} style={{ overflowX: "auto", }}>
                                    <TreeView
                                        className={styles.tree}
                                        // defaultExpanded={[props.state.data.username ? `${tree?.id}` : `${id}`]}
                                        style={{ position: 'relative', zIndex: 0 }}
                                    >
                                        {/* {props.state.data.username && tree.id !== id ? renderTree(tree) : _.map(tree.children, node => (renderTree(node)))} */}
                                        {_.map(tree.children, node => (renderTree(node)))}
                                    </TreeView>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    :
                    <Card style={{ border: 'none', background: 'transparent' }} variant="outlined">
                        <CardContent className='txt-center' style={{ height: 400, borderRadius: 0 }}>
                            <div className='p-t-40'>
                                <img src='/images/general/no-genealogy.png' alt='no-transaction' style={{ width: 152, height: 118 }} loading='lazy' />
                            </div>
                            <p className='txt-white fs-18 p-t-16' style={{ paddingTop: 16, fontWeight: 600 }}>{t('genealogy.noRecord')}</p>
                            <p className='txt-white fs-14 p-t-8' style={{ color: '#D0D5DD' }}>{t('genealogy.noRecordDetails')}</p>
                        </CardContent>
                    </Card>
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    tree: {
        flexGrow: 1,
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        color: '#c1c1c1',
        padding: theme.spacing(0.5, 0),
    },
    labelText: {
        fontWeight: 'inherit',
        marginLeft: 10,
        textTransform: "lowercase"
    },
    labelSubText: {
        marginLeft: 10,
        fontStyle: 'italic'
    },
    downlineBoxDesign: {
        position: 'relative',
        marginBottom: 15,
        padding: '15px 15px 15px 0px',
        borderRadius: 15,
        backgroundColor: '#0D132C'
    },
    subline: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '-0.16px',
        color: '#98A2B3',
        fontFamily: 'sans-serif'
    },
    subline2: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '130%',
        letterSpacing: '-0.12px',
        color: '#98A2B3',
        fontFamily: 'sans-serif'
    },
    downlineMemberUsername: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '20px',
        color: '#FFF'
    }
}));