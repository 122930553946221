import { Grid } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { currencyFormat } from '@utils/Tools';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import { FiEye } from 'react-icons/fi';
import 'swiper/css/bundle';

export default function Commission(props) {
    const { i18n } = useTranslation();
    const styles = useStyles();
    const { state, setState } = props;
    const theme = useTheme();

    return (
        <Grid container display='flex' flexDirection='column' alignContent='center' justifyContent='center' textAlign='center'>
            <Grid item xs={12}>
                <Swiper
                    slidesPerView={1}
                    navigation={true}
                    modules={[Navigation]}
                    onSlideChange={(e) => setState({ ...state, commissionIndex: e.activeIndex })}
                >
                    {
                        _.size(state.commissions) > 0 && _.map(state.commissions, (detail, index) => {
                            return (
                                <SwiperSlide key={detail.id}>
                                    <div>
                                        <p className='txt-white fs-18 inter-normal'>{detail.name[i18n.resolvedLanguage]}
                                            {
                                                state.walletDecimal === 2 ?
                                                    <FiEye style={{ width: 14, height: 14, color: '#ffffff', cursor: 'pointer', paddingLeft: 10, position: 'absolute' }} onClick={() => setState({ ...state, walletDecimal: 4 })} />
                                                    :
                                                    <FiEye style={{ width: 14, height: 14, color: theme.palette.primary.main, cursor: 'pointer', paddingLeft: 10, position: 'absolute' }} onClick={() => setState({ ...state, walletDecimal: 2 })} />
                                            }
                                        </p>
                                        <p className='txt-white fs-24 poppins-semibold p-t-8'>$ {currencyFormat(detail.total_amount * 1, state.walletDecimal)}</p>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({

}));