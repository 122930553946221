import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IconButton } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { changeLanguage } from '@actions';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

export default function Maintenance() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const { addAlert, setLoading } = useNotificationLoading();
    const [serverTime, setServerTime] = useState("");
    const [maintenance, setMaintenance] = useState({
        force: '',
        start: '',
        end: '',
    });
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.general);
    const isMountedRef = useRef(null);
    let navigate = useNavigate();

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`/maintenance`).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                if (!response.maintenance_mode) {
                    navigate('/');
                }
                setMaintenance({
                    force: response.force_maintenance,
                    start: response.maintenance_start,
                    end: response.maintenance_end,
                });
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime(moment().format("YYYY-MM-DD HH:mm:ss"))
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    return (
        <div className='p-lr-22 p-t-25 p-b-50' style={{ minHeight: '90vh' }}>
            <div className='flex-sb-m'>
                <div>
                    <img src='/logo_text2.png' alt="logo" style={{ width: 130 }} loading='lazy' />
                </div>
                {
                    language === 'en' ?
                        <IconButton onClick={() => changeSystemLanguage('cn')}>
                            <p className='fs-16 txt-upper' style={{ fontWeight: 500 }}>中文</p>
                        </IconButton>
                        :
                        <IconButton onClick={() => changeSystemLanguage('en')}>
                            <p className='fs-16 txt-upper' style={{ fontWeight: 500 }}>EN</p>
                        </IconButton>
                }
            </div>
            <div className='p-lr-15 p-t-8 p-b-15 txt-right txt-white fs-14'>
                <span>{t('general.serverTime')}: {serverTime}</span>
            </div>
            <div className='p-lr-20 p-t-128'>
                <img src='images/general/maintenance.png' alt='maintenance' className='w-full' loading='lazy' />
            </div>
            <div className='txt-center p-t-32' style={{ color: '#82512D' }}>
                <p className='fs-18 lh-10 txt-white' style={{ fontWeight: 600 }}>{t('maintenance.title')}</p>
                {/* {
                    !maintenance.force
                        ?
                        <p className='p-t-3' style={{ fontWeight: 500 }}>{t('maintenance.dailyMaintenance') + maintenance.start + ' - ' + maintenance.end}</p>
                        :
                        null
                } */}
                <p className='p-t-4 fs-16' style={{ color: '#98A2B3' }}>{t('maintenance.content')}</p>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({

}));