import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Typography, Collapse, Alert, AlertTitle, Button } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeLoginAccess } from '@utils/ApiAction';
import { changeLanguage } from '@actions';
import { storePaths } from '@utils/Tools';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { HiChevronRight, HiChevronDown } from "react-icons/hi2";
import { PiUserSquare, PiKey, PiLockKeyLight } from "react-icons/pi";
import { IoLanguage } from "react-icons/io5";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { BiMoneyWithdraw } from "react-icons/bi";

const MenuListing = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const styles = useStyles();
    let location = useLocation();
    let navigate = useNavigate();
    const [option, setOption] = useState({
        openLang: false,
    })
    const { sub_email } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();

    const navigateMenu = (path) => {
        navigate(path, { state: { from: storePaths(location) } });
        handleLangClose();
    }

    const handleLangChange = () => {
        setOption({ ...option, openLang: !option?.openLang });
    }

    const handleLangClose = () => {
        setOption({ ...option, openLang: false });
    }

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
        handleLangClose();
    };

    const logout = () => {
        removeLoginAccess();
        navigate('/login', { replace: true });
        handleLangClose();
    };

    const resendEmailUpdateVerification = () => {
        setLoading(true);
        getUrl(`/user/send-email-verification-url/update`).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div className='flex-col app-header-margin' style={{ gap: 24 }}>
            <div className="dis-flex flex-col" style={{ gap: 24 }}>
                {
                    sub_email ?
                        <div>
                            <Alert severity="info">
                                <AlertTitle style={{ color: 'black', fontWeight: 'bold' }}>{t('general.info')}</AlertTitle>
                                <Typography style={{ color: 'black', textAlign: 'left', fontWeight: 'bold', wordBreak: 'break-all' }} variant='body2' >{t('user.subEmail') + ': ' + sub_email}</Typography>
                                <Typography style={{ color: 'black', textAlign: 'left' }} variant='body2' >{t('user.verifyEmail')}</Typography>
                                <Button variant="contained" size="small" onClick={resendEmailUpdateVerification} style={{ color: '#fff', marginTop: 15, width: 'fit-content' }}>Resend Verification Link</Button>
                            </Alert>
                        </div>
                        : null
                }
                <div onClick={() => navigateMenu('/setting/profile')} className='listing-animate flex-sb m-b-10 pointer flex-row flex-m'>
                    <div className="flex-row dis-flex flex-m" style={{ gap: 16 }}>
                        <PiUserSquare style={{ color: 'white', width: 30, height: 30 }} />
                        <div className="flex-col">
                            <Typography variant="subtitle2">{t('title.profile')}</Typography>
                            <Typography variant="body2" style={{ color: "#98A2B3" }}>{t('setting.profileDesc')}</Typography>
                        </div>
                    </div>
                    <HiChevronRight className="img-style" style={{ color: 'white' }} />
                </div>
                <div onClick={() => navigateMenu('/setting/crypto-address')} className='listing-animate flex-sb m-b-10 pointer flex-row flex-m'>
                    <div className="flex-row dis-flex flex-m" style={{ gap: 16 }}>
                        <BiMoneyWithdraw style={{ color: 'white', width: 30, height: 30 }} />
                        <div className="flex-col">
                            <Typography variant="subtitle2">{t('title.cryptoAddress')}</Typography>
                            <Typography variant="body2" style={{ color: "#98A2B3" }}>{t('setting.cryptoAddressDesc')}</Typography>
                        </div>
                    </div>
                    <HiChevronRight className="img-style" style={{ color: 'white' }} />
                </div>
                <div onClick={() => navigateMenu('/setting/password')} className='listing-animate flex-sb m-b-10 pointer flex-row flex-m'>
                    <div className="flex-row dis-flex flex-m" style={{ gap: 16 }}>
                        <PiKey style={{ color: 'white', width: 30, height: 30 }} />
                        <div className="flex-col">
                            <Typography variant="subtitle2">{t('title.password')}</Typography>
                            <Typography variant="body2" style={{ color: "#98A2B3" }}>{t('setting.passwordDesc')}</Typography>
                        </div>
                    </div>
                    <HiChevronRight className="img-style" style={{ color: 'white' }} />
                </div>
                <div onClick={() => navigateMenu('/setting/security-password')} className='listing-animate flex-sb m-b-10 pointer flex-row flex-m'>
                    <div className="flex-row dis-flex flex-m" style={{ gap: 16 }}>
                        <PiLockKeyLight style={{ color: 'white', width: 30, height: 30 }} />
                        <div className="flex-col">
                            <Typography variant="subtitle2">{t('title.securityPassword')}</Typography>
                            <Typography variant="body2" style={{ color: "#98A2B3" }}>{t('setting.securityPasswordDesc')}</Typography>
                        </div>
                    </div>
                    <HiChevronRight className="img-style" style={{ color: 'white' }} />
                </div>
                {/* <div>
                    <div onClick={handleLangChange} className='listing-animate flex-sb m-b-10 pointer flex-row flex-m'>
                        <div className="flex-row dis-flex flex-m" style={{ gap: 16 }}>
                            <IoLanguage style={{ color: 'white', width: 30, height: 30 }} />
                            <div className="flex-col">
                                <Typography variant="subtitle2">{t('general.language')}</Typography>
                                <Typography variant="body2" style={{ color: "#98A2B3" }}>{t('setting.languageDesc')}</Typography>
                            </div>
                        </div>
                        {option?.openLang ? <HiChevronDown className="img-style" style={{ color: 'white' }} /> : <HiChevronRight className="img-style" style={{ color: 'white' }} />}
                    </div>
                    <Collapse in={option?.openLang} timeout="auto" unmountOnExit style={{ paddingLeft: 45 }}>
                        <div className='flex-c flex-col m-b-20'>
                            <Typography onClick={() => changeSystemLanguage('en')} variant="subtitle2" style={{ cursor: 'pointer', paddingTop: 5 }}>{t('general.en')}</Typography>
                            <Typography onClick={() => changeSystemLanguage('cn')} variant="subtitle2" style={{ cursor: 'pointer', paddingTop: 5 }}>{t('general.cn')}</Typography>
                        </div>
                    </Collapse>
                </div> */}
                <div onClick={logout} className='listing-animate flex-sb m-b-10 pointer flex-row flex-m'>
                    <div className="flex-row dis-flex flex-m" style={{ gap: 16 }}>
                        <RiLogoutCircleRLine style={{ color: 'white', width: 30, height: 30 }} />
                        <div className="flex-col">
                            <Typography variant="subtitle2">{t('button.logout')}</Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    secondary: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
}));

export default MenuListing;