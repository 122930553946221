import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Link, Slide } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl } from '@utils/ApiAction';
import { currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineViewAgenda } from 'react-icons/md';

import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import { storePaths } from '@utils/Tools';

import Commission from './Commission';
import Wallet from './Wallet';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="lh-0" p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

//Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Index(props) {
    const [state, setState] = useState({
        pageIndex: null,
        subPage: 'wallet',
        wallets: [],
        walletSetting: [],
        walletIndex: 0,
        commissions: [],
        commissionIndex: 0,
        walletDecimal: 2,
    });

    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let location = useLocation();
    let navigate = useNavigate();
    let { page } = useParams();

    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    //Dialog
    const [dialog, setDialog] = useState({
        open: false,
        data: []
    });
    const handleClickOpen = (data) => {
        setDialog({ ...dialog, open: true, data: data });
    };
    const handleClose = () => {
        setDialog({ ...dialog, open: false, data: [] });
    };

    useEffect(() => {
        if (page) {
            if (_.includes(['wallet', 'commission'], page)) {
                setState({
                    ...state,
                    pageIndex: page === 'commission' ? 1 : 0,
                    subPage: page,
                })
            }
        }
    }, [page]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        if (state.pageIndex !== null) {
            let apiUrl = '/user/wallets';
            if (state.pageIndex === 0) {
            } else if (state.pageIndex === 1) {
                apiUrl = '/user/commissions';
            }

            getUrl(apiUrl).then(response => {
                if (response.status && isMountedRef.current) {
                    if (state.pageIndex === 0) {
                        setState({ ...state, wallets: Object.values(response.data.wallet_list), walletSetting: response.data.wallet_setting });
                    } else if (state.pageIndex === 1) {
                        setState({ ...state, commissions: Object.values(response.data) });
                    }
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        }

        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [state.pageIndex]);

    const displayPage = () => {
        if (state.subPage === 'wallet') {
            return <Wallet state={state} setState={setState} />
        }
        else if (state.subPage === 'commission') {
            return <Commission state={state} setState={setState} />
        }
    }

    const handleChange = (value) => {
        navigate(`/wallets/${value === 1 ? 'commission' : 'wallet'}`);
    };

    const detailsSwitch = (type, data) => {
        switch (type) {
            case 'WalletAction':
                switch (data.transaction_type) {
                    case 'withdraw':
                        return (
                            <div>
                                <div className='p-b-20'>
                                    <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('title.cryptoAddress')}</p>
                                    <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{data.wallet_transactionable?.details?.toAddress}</p>
                                </div>
                                <div className='p-b-20'>
                                    <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.txid')}</p>
                                    <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{data.wallet_transactionable?.details?.txid}</p>
                                </div>
                                <div className='p-b-20'>
                                    <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('user.status')}</p>
                                    <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{data.wallet_transactionable?.status_display?.details[i18n.resolvedLanguage]}</p>
                                </div>
                            </div>
                        );
                    default:
                        return null;
                }
            default:
                return null;
        }
    }

    const displayStatus = (status) => {
        let img_text = '';
        switch (status) {
            case 20:
            case 40:
                img_text = 'pending';
                break;
            case 60:
                img_text = 'success';
                break;
            default:
                img_text = 'failed';
                break;
        }
        return <img src={`/images/wallet/transaction-${img_text}.png`} alt="status" className={styles.iconStyle} />;
    }

    return (
        <div className='p-b-150 p-t-95' style={{ minHeight: '100vh', overflowY: 'hidden' }}>
            <Box style={{ backgroundColor: '#0D132C' }}>
                <Box className="p-lr-15">
                    {!_.isNull(state.pageIndex) ? <>
                        <Grid container spacing={2} alignItems="center" style={{ paddingTop: 20 }}>
                            <Grid item xs={12} display="flex" justifyContent="space-evenly" alignItems="center">
                                <Button
                                    value={0}
                                    onClick={() => handleChange(0)}
                                    className={state.pageIndex === 0 ? styles.selectedButton : styles.button}
                                    size="small"
                                    variant="contained"
                                >
                                    <p className='fs-14 inter-normal'>{t('title.wallet')}</p>
                                </Button>
                                <Button
                                    value={1}
                                    onClick={() => handleChange(1)}
                                    className={state.pageIndex === 1 ? styles.selectedButton : styles.button}
                                    size="small"
                                    variant="contained"
                                >
                                    <p className='fs-14 inter-normal'>{t('title.commission')}</p>
                                </Button>
                            </Grid>
                            {/* <Grid item xs={1} ml={-2} justifyContent="flex-end">
                                <MdOutlineViewAgenda className='fs-20 txt-white m-t-4 m-r-5 pointer' />
                            </Grid> */}
                        </Grid>
                        <div className='p-tb-20'>
                            {/* /////////  Wallet /////////*/}
                            <TabPanel value={state.pageIndex} index={0} dir={theme.direction}>
                                <Grid item xs={12}>
                                    {displayPage()}
                                </Grid>
                            </TabPanel>

                            {/* /////////  Commission  /////////*/}
                            <TabPanel value={state.pageIndex} index={1} dir={theme.direction}>
                                <Grid item xs={12}>
                                    {displayPage()}
                                </Grid>
                            </TabPanel>
                        </div>
                    </> : null}
                </Box>
            </Box>
            <Box style={{ backgroundColor: '#121B3E', paddingTop: 32, paddingLeft: 15, paddingRight: 15 }}>
                <div className='flex-sb-m p-tb-20'>
                    <div>
                        <p className='txt-white'>{t('wallet.transactionHistory')}</p>
                    </div>
                    <Link component={RouterLink} to={state.pageIndex === 0 ? `/wallet/transactionHistory/${state.wallets[state.walletIndex]?.id}` : `/commission/transactionHistory/${state.commissions[state.commissionIndex]?.id}`} state={{ from: storePaths(location) }} underline='none'>
                        <div>
                            <p className='txt-white pointer'>{t('general.viewAll')}</p>
                        </div>
                    </Link>
                </div>
                {
                    state.pageIndex === 0 ? // wallet
                        <>
                            {
                                _.size(state?.wallets[state.walletIndex]?.transaction) > 0 ?
                                    <>
                                        {
                                            _.map(state.wallets[state.walletIndex].transaction, (detail, index) => {
                                                return (
                                                    <div key={index} style={{ padding: '16px 0', borderTop: index !== 0 ? '0.5px solid #202E63' : '' }} onClick={() => handleClickOpen(detail)} className='pointer'>
                                                        <Grid container display='flex' justifyContent='space-between' style={{}}>
                                                            <Grid item className='txt-white fs-16' style={{ fontWeight: 600 }}>{detail?.transaction_code_display.details[i18n.resolvedLanguage]}{detail?.transaction_type == 'withdraw' ? displayStatus(detail?.wallet_transactionable?.status || 0) : null}</Grid>
                                                            <Grid item style={{ color: detail.factor === 1 ? '#32D583' : '#F04438', fontWeight: 600, fontSize: 16 }}>{detail.factor === 1 ? '+ ' : '- '}{currencyFormat(detail.amount * 1, state.walletDecimal)}</Grid>
                                                        </Grid>
                                                        <Grid container display='flex' justifyContent='space-between'>
                                                            <Grid item className='fs-12' style={{ color: '#B6B8C3' }}>{t('wallet.transactionId') + ': '}{detail.ulid}</Grid>
                                                            <Grid item className='fs-12' style={{ color: '#B6B8C3' }}>{detail.created_at}</Grid>
                                                        </Grid>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            _.size(state?.wallets[state.walletIndex]?.transaction) >= 10 ?
                                                <Link component={RouterLink} to={`/wallet/transactionHistory/${state.wallets[state.walletIndex].id}`} state={{ from: storePaths(location) }} underline='none' style={{ textAlign: 'center' }}>
                                                    <div className='p-t-15 txt-upper'>
                                                        <p className='txt-white pointer'>{t('general.viewAll')}</p>
                                                    </div>
                                                </Link> : null
                                        }
                                    </>
                                    :
                                    <div className='txt-center' style={{ paddingTop: 40 }}>
                                        <img src='/images/general/no-transaction.png' alt='no-transaction' style={{ width: 152, height: 118 }} loading='lazy' />
                                        <p className='txt-white fs-16' style={{ paddingTop: 16, fontWeight: 600 }}>{t('table.noRecord')}</p>
                                    </div>
                            }
                        </>
                        :
                        <>
                            {
                                _.size(state?.commissions[state.commissionIndex]?.transaction) > 0 ?
                                    <>
                                        {
                                            _.map(state.commissions[state.commissionIndex].transaction, (detail, index) => {
                                                return (
                                                    <div key={index} style={{ padding: '16px 0', borderTop: index !== 0 ? '0.5px solid #202E63' : '' }} onClick={() => handleClickOpen(detail)} className='pointer'>
                                                        <Grid container display='flex' justifyContent='space-between' style={{}}>
                                                            <Grid item className='txt-white fs-16' style={{ fontWeight: 600 }}>{detail?.bonus.name[i18n.resolvedLanguage]}</Grid>
                                                            <Grid item style={{ color: '#32D583', fontWeight: 600, fontSize: 16 }}>{'+ '}{currencyFormat(detail.amount * 1, state.walletDecimal)}</Grid>
                                                        </Grid>
                                                        <Grid container display='flex' justifyContent='space-between'>
                                                            <Grid item className='fs-12' style={{ color: '#B6B8C3' }}>{t('wallet.transactionId') + ': '}{detail.id}</Grid>
                                                            <Grid item className='fs-12' style={{ color: '#B6B8C3' }}>{detail.bonus_date}</Grid>
                                                        </Grid>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            _.size(state?.commissions[state.commissionIndex]?.transaction) >= 10 ?
                                                <Link component={RouterLink} to={`/commission/transactionHistory/${state.commissions[state.commissionIndex].id}`} state={{ from: storePaths(location) }} underline='none' style={{ textAlign: 'center' }}>
                                                    <div className='p-t-15 txt-upper'>
                                                        <p className='txt-white pointer'>{t('general.viewAll')}</p>
                                                    </div>
                                                </Link> : null
                                        }
                                    </>
                                    :
                                    <div className='txt-center' style={{ paddingTop: 40 }}>
                                        <img src='/images/general/no-transaction.png' alt='no-transaction' style={{ width: 152, height: 118 }} loading='lazy' />
                                        <p className='txt-white fs-16' style={{ paddingTop: 16, fontWeight: 600 }}>{t('table.noRecord')}</p>
                                    </div>
                            }
                        </>
                }
                <Dialog
                    open={dialog.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                >
                    <DialogTitle className='w-full fs-15 txt-center txt-upper' style={{ borderBottom: '2px dashed #fff3', fontWeight: 'bold', paddingBottom: 20, lineHeight: 1.1 }}>
                        {state.pageIndex === 1 ? dialog.data?.bonus?.name[i18n.resolvedLanguage] : dialog.data?.transaction_code_display?.details[i18n.resolvedLanguage]}
                        <br />
                        <span className='fs-18 txt-title p-t-10' style={{ fontWeight: 'normal', color: '#9ba9c7' }}>{t('wallet.transactionDetails')}</span>
                    </DialogTitle>
                    <DialogContent className='txt-white fs-14 lh-12 w-full'>
                        <div className='p-tb-15'>
                            <div className='p-b-20'>
                                <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.transactionId')}:</p>
                                <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{state.pageIndex === 0 ? dialog.data.ulid : dialog.data.id}</p>
                            </div>
                            {
                                state.pageIndex === 0 ?
                                    <>{detailsSwitch(dialog.data?.transactionable_type, dialog.data)}</>
                                    :
                                    ( _.includes(['roi-daily-bonus', 'roi-monthly-bonus'], dialog?.data?.bonus?.bonus_type)) ?   
                                        <div className='p-b-20'>
                                            <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.package')}</p>
                                            <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{_.includes(i18n.resolvedLanguage, JSON.parse(dialog.data?.plan_name)) ? JSON.parse(dialog.data?.plan_name)[i18n.resolvedLanguage] : JSON.parse(dialog.data?.plan_name)?.['en']}{' (' + dialog.data?.plan_history_id + ')'}</p>
                                        </div>
                                        :
                                        <div className='p-b-20'>
                                            <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.downlineUsername')}</p>
                                            <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{dialog.data?.downline_username}</p>
                                        </div>
                            }
                            <div className='p-b-20'>
                                <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.dateTime')}</p>
                                <p className='fs-15' style={{ fontWeight: 'bold' }}>{state.subPage === 'commission' ? dialog?.data?.bonus_date : dialog?.data?.updated_at}</p>
                            </div>
                        </div>
                        <div className='p-t-20 flex-sb-m' style={{ borderTop: '2px dashed #fff3' }}>
                            <p className='p-b-5 txt-upper' style={{ color: '#fff', whiteSpace: 'break-spaces' }}>{t('wallet.totalAmount')}</p>
                            <p style={{ fontWeight: 'bold', wordBreak: 'break-all', textAlign: 'right' }} className='fs-21'>USD <br /> {currencyFormat((dialog.data?.amount || 0) * 1, 4)}</p>
                        </div>
                    </DialogContent>
                </Dialog>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    selectedButton: {
        borderRadius: '50px',
        color: 'white',
        backgroundColor: '#FDEDE9',
        padding: '8px 12px 8px 12px',
        minWidth: 0,
        width: '150px',
    },
    button: {
        borderRadius: '50px',
        color: '#98A2B3',
        backgroundColor: '#344054',
        background: '#344054',
        padding: '8px 12px 8px 12px',
        minWidth: 0,
        width: '150px',
    },
    iconStyle: {
        height: 15,
        width: 14,
        marginLeft: 10
    }
}));