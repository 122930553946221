import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import QRCode from 'qrcode';

import "@css/tree.css";
import { Box, Button, Dialog, Grid, IconButton, Typography, createTheme } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import GenealogySponsor from './GenealogySponsor';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { MdDownload, MdQrCode } from 'react-icons/md';

const QrImageDownload = ({ imageUrl, qrValue, qrSize = 112 }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const generateQR = async () => {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');

            // Create a temporary canvas for QR code generation
            const qrCanvas = document.createElement('canvas');
            const qrContext = qrCanvas.getContext('2d');

            // Set dimensions for the QR code canvas
            qrCanvas.width = qrSize;
            qrCanvas.height = qrSize;

            // Load the base image
            const image = new Image();
            image.src = imageUrl;
            image.crossOrigin = "Anonymous"; // Handle CORS if necessary
            image.onload = () => {
                // Draw the base image on the main canvas
                context.drawImage(image, 0, 0, canvas.width, canvas.height);

                // Generate the QR code on the temporary canvas
                QRCode.toCanvas(qrCanvas, qrValue, {
                    width: qrSize,
                    height: qrSize,
                    margin: 0,
                    errorCorrectionLevel: 'H',
                    color: {
                        // dark: '#00F',  // Blue dots
                        light: '#0000' // Transparent background
                    }
                }, (error) => {
                    if (error) console.error(error);

                    // Calculate the QR code's position on the main canvas
                    const qrX = (canvas.width - qrSize) / 2 + 59; // 59 pixels from the center left
                    const qrY = (canvas.height - qrSize) / 2 + 28; // 28 pixels from the center top

                    // Draw the temporary QR code canvas onto the main canvas at the specified position
                    context.drawImage(qrCanvas, qrX, qrY);
                });
            };
        };

        generateQR();
    }, [imageUrl, qrValue, qrSize]);

    const downloadImage = () => {
        const canvas = canvasRef.current;
        const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        const link = document.createElement('a');
        link.href = image;
        link.download = 'qr-invite.png'; // Filename for the downloaded image
        document.body.appendChild(link); // Required for Firefox
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <canvas ref={canvasRef} width="1000" height="1000" style={{ display: 'none' }} hidden></canvas>
            <IconButton color="primary" onClick={downloadImage} aria-label="download qr image">
                <MdDownload />
            </IconButton>
        </div>
    );
};

export default function Index(props) {
    const [state, setState] = useState({
        pageIndex: null,
        subPage: 'sponsor',
        totalDirectDownlines: 0,
        totalDownlines: 0,
        data: [],
    });
    const [showQr, setShowQr] = useState({
        open: false,
        url: '',
    });
    const { username } = useSelector(state => state.user);

    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let navigate = useNavigate();
    let { page } = useParams();
    // let username = useQuery().get('u');
    const shareLink = useRef(null);

    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        if (page) {
            if (_.includes(['sponsor', 'placement'], page)) {
                setState({
                    ...state,
                    pageIndex: page === 'placement' ? 1 : 0,
                    subPage: page,
                })
            }
        }
    }, [page]);

    const copyShareLink = useCallback((e) => {
        shareLink.current.select();
        document.execCommand('copy');
        e.target.focus();

        addAlert('', t('general.copied'), "success");
    }, []);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        if (state.pageIndex !== null) {
            let apiUrl = 'sponsor-genealogy';
            if (state.pageIndex === 0) {
            } else if (state.pageIndex === 1) {
                apiUrl = 'placement-genealogy';
            }

            getUrl(apiUrl).then(response => {
                if (response.status && isMountedRef.current) {
                    setState({ ...state, totalDirectDownlines: response.data.tree_data.total_direct_downline, totalDownlines: response.data.tree_data.total_downline, data: response.data.tree_data });
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        }

        return () => isMountedRef.current = false;
    }, [state.pageIndex]);

    return (
        <div className='dis-flex' style={{ paddingLeft: 15, paddingRight: 15, marginTop: 100, overflowX: 'hidden', gap: 24, flexDirection: 'column', minHeight: '110vh' }}>
            {/* Self data */}
            <div className="flex-sb-m dark-blue-bg w-full p-all-16" style={{ borderRadius: 6 }}>
                <div style={{ maxHeight: 130, maxWidth: 130 }}>
                    <img style={{ objectFit: 'cover' }} className='s-full' src={`/images/general/genealogy.png`} alt="genealogy" />
                </div>
                <div className="flex-c-t" style={{ flexDirection: 'column', display: 'flex', width: '50%', gap: 16 }}>
                    <div className="flex-sb-m" style={{ flexDirection: 'row', display: 'flex', width: '90%' }}>
                        <div className="flex-col" style={{ gap: 8 }}>
                            <Typography variant="body2">{t('genealogy.totalDirect')}</Typography>
                            <Typography color="primary" variant="subtitle2">{state.totalDirectDownlines}</Typography>
                        </div>
                        <div className=" flex-col" style={{ gap: 8 }}>
                            <Typography variant="body2">{t('genealogy.total')}</Typography>
                            <Typography color="primary" variant="subtitle2">{state.totalDownlines}</Typography>
                        </div>
                    </div>
                </div>
            </div>
            {/* Downline */}
            <div >
                <div className="p-lr-20 flex-sb-m" style={{ gap: 8 }}>
                    <div>
                        <Typography className={styles.subline}>{t('genealogy.member')}</Typography>
                        <Typography className={styles.subline2}>{t('genealogy.memberTotal', { 'amount': state.totalDownlines })}</Typography>
                    </div>
                    <div>
                        <MdQrCode className='txt-white fs-35 pointer' onClick={() => setShowQr({ ...showQr, open: true, url: `${process.env.REACT_APP_LOCAL_URL}/register?referral=${username}` })} />
                    </div>
                </div>
                <div>
                    <GenealogySponsor state={state} />
                </div>
            </div>
            <Dialog
                open={showQr.open}
                onClose={() => setShowQr({ ...state, open: false })}
            >
                <div className={`p-lr-10 p-t-40 clwhite flex-m`} >
                    <p className='fs-21 txt-upper'><b>{username}</b></p>
                </div>
                <Box className='p-b-50'>
                    <Box display="flex" flexDirection="column" width="100%" alignItems="center" justifyContent="center" className="p-lr-20 p-b-40 p-t-10">
                        {/* <QRCode value={qrUrl} renderAs="svg" fgColor={theme.palette.primary.main} /> */}
                        <div className='p-all-10 bgwhite' style={{ borderRadius: 15 }}>
                            <QRCodeSVG value={showQr.url} />
                        </div>
                        <QrImageDownload imageUrl={`/images/qrbg-${i18n.language === "cn" ? "cn" : "en"}.png`} qrValue={showQr.url} qrSize={112} />
                    </Box>
                    <div className='p-lr-55'>
                        <form>
                            <textarea ref={shareLink} value={showQr.url} readOnly style={{ border: 'none', background: 'transparent', resize: 'none', outline: 'none', fontSize: 15 }} className='w-full txt-center clwhite' />
                        </form>
                        {document.queryCommandSupported('copy') &&
                            <Grid item style={{ textAlign: "center", paddingTop: 10 }}>
                                <Button variant="contained" style={{ minWidth: 200 }} onClick={copyShareLink}>{t('general.copy')}</Button>
                            </Grid>
                        }
                    </div>
                </Box>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    subline: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '130%',
        letterSpacing: '-0.16px',
        color: '#FFF',
        fontFamily: 'sans-serif'
    },
    subline2: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '130%',
        letterSpacing: '-0.12px',
        color: '#98A2B3',
        fontFamily: 'sans-serif'
    }
}));

const tabStyle = createTheme({
    overrides: {
        MuiTab: {
            textColorPrimary: {
                color: '#dfdfdf',
                "&$selected": {
                    color: '#dbc685',
                },
            }
        },
        PrivateTabIndicator: {
            colorPrimary: {
                backgroundColor: '#dbc685'
            }
        }
    }
})