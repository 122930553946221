import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { currencyFormat } from '@utils/Tools';

import { MdOutlineInfo, MdVisibility, MdVisibilityOff } from "react-icons/md";


export default function GameTopUp(props) {
    const theme = useTheme();
    const { t } = useTranslation();
    const styles = useStyles();
    const { addAlert, setLoading } = useNotificationLoading();
    let { id } = useParams();
    const [inputErrors, setInputErrors] = useState({});

    const [state, setState] = useState({
        wallet: [],
        rule: [],
        amount: 0,
        securityPassword: ""
    });
    const [showPassword, setShowPassword] = useState({
        securityPassword: false,
    });

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    useEffect(() => {
        setLoading(true);
        getUrl(`/wallets/setting/game-topup/${id}`).then(response => {
            if (response.status) {
                setState({ ...state, wallet: response.data.balance, rule: response.data.rule });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    const gameTopup = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            security_password: state.securityPassword,
            amount: state.amount,
            wallet_id: id,
        }
        postUrl(`/game-topup`, params).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.topupSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.topupError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Box className="app-header-margin">
            <div>
                <div className='flex-sb-m p-b-40'>
                    <div className='p-tb-20 p-lr-10 txt-left' style={{ borderRadius: 8, background: 'linear-gradient(90deg, #55D3FF,#684EFF)', width: '30%' }}>
                        <p className='fs-13 txt-truncate' style={{ color: '#FAC7BC' }}>{t('withdrawal.balance')}</p>
                        <p className='fs-14 p-t-4' style={{ fontWeight: 600, color: '#ffffff' }}>{'$ ' + currencyFormat(state.wallet?.balance * 1 || 0, 2)}</p>
                    </div>
                    <div className='p-tb-20 p-lr-10 txt-left' style={{ borderRadius: 8, background: 'linear-gradient(90deg, #55D3FF,#684EFF)', width: '30%' }}>
                        <p className='fs-13 txt-truncate' style={{ color: '#FAC7BC' }}>{t('gameTopup.availableFund')}</p>
                        <p className='fs-14 p-t-4' style={{ fontWeight: 600, color: '#ffffff' }}>{'$ ' + currencyFormat((state.wallet?.balance - state.wallet?.locked_balance) * 1 || 0, 2)}</p>
                    </div>
                    <div className='p-tb-20 p-lr-10 txt-left' style={{ borderRadius: 8, background: 'linear-gradient(90deg, #55D3FF,#684EFF)', width: '30%' }}>
                        <p className='fs-13 txt-truncate' style={{ color: '#FAC7BC' }}>{t('withdrawal.lockedBalance')}</p>
                        <p className='fs-14 p-t-4' style={{ fontWeight: 600, color: '#ffffff' }}>{'$ ' + currencyFormat(state.wallet?.locked_balance * 1 || 0, 2)}</p>
                    </div>
                </div>
                <div>
                    <TextField
                        variant="standard"
                        fullWidth
                        name="amount"
                        label={t('gameTopup.amount')}
                        placeholder={t('gameTopup.amount')}
                        type="number"
                        value={state.amount || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                        error={inputErrors && inputErrors.amount ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            disableUnderline: true
                        }}
                    />
                </div>
                <div className='fs-14 p-t-10 p-lr-15' style={{ color: '#60667E' }}>
                    <p><MdOutlineInfo style={{ marginRight: 8 }} />{t('gameTopup.minimumGameTopup') + state.rule?.min_amount}</p>
                </div>
                <div className='p-tb-25'>
                    <TextField
                        variant="standard"
                        fullWidth
                        name="securityPassword"
                        label={t('user.securityPassword')}
                        placeholder={t('user.securityPassword')}
                        type={showPassword.securityPassword ? 'text' : 'password'}
                        value={state.securityPassword || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                        error={inputErrors && inputErrors.security_password ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => handleClickShowPassword('securityPassword')}
                                    >
                                        {showPassword.securityPassword ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className='p-t-25 w-full'>
                    <Button variant="contained" onClick={gameTopup} style={{ paddingTop: 10, paddingBottom: 10 }} fullWidth>{t('button.submit')}</Button>
                </div>
            </div>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({

}));