import React, { memo, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, loginGame, postUrl } from '@utils/ApiAction';
import { storePlayerId } from '@actions';
import useNotificationLoading from '@utils/useNotificationLoading';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { currencyFormat } from '@utils/Tools';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import 'swiper/css/bundle';

import { Button, LinearProgress, Typography, Tooltip, ClickAwayListener, Link, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, } from '@mui/material';

import { FiEye, FiChevronRight } from "react-icons/fi";
import { storePaths } from '@utils/Tools';

import VerifyEmailDialog from '@components/VerifyEmailDialog';



const Home = memo(() => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();
    const dispatch = useDispatch();
    const { accessToken, accountSetting } = useSelector(state => state.general);
    const { player_id, email_verified_at } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const [walletTooltip, setWalletTooltip] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl('dashboard').then(response => {
            if (response.status && isMountedRef.current) {
                setData({ ...data, ...response.data });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });

        return () => isMountedRef.current = false;
    }, []);

    const WalletInfo = (props) => {
        let { decimal, balance, locked_balance } = props.info;
        let withdrawableBalance = parseFloat(balance) - parseFloat(locked_balance || 0);
        balance = currencyFormat(parseFloat(balance), decimal);
        locked_balance = currencyFormat(parseFloat(locked_balance || 0), decimal);
        withdrawableBalance = currencyFormat(withdrawableBalance, decimal);

        return (
            <div className="dis-flex flex-col-l-m" style={{ flexDirection: 'column', gap: 8 }}>
                <Typography>{`${t('home.currentBalance')}: $ ${balance}`}</Typography>
                <Typography>{`${t('home.withdrawable')}: $ ${withdrawableBalance}`}</Typography>
                <Typography>{`${t('home.locked')}: $ ${locked_balance}`}</Typography>
            </div>
        )
    }

    const showWalletTooltip = (walletId) => {
        setWalletTooltip({ ...walletTooltip, [walletId]: true })
    }

    const hideWalletTooltip = (walletId) => {
        setWalletTooltip({ ...walletTooltip, [walletId]: false })
    }

    const createGameAccount = () => {
        setLoading(true);
        postUrl(`/game-register`, {}).then(response => {
            if (response.status) {
                dispatch(storePlayerId(response.player_id));
                addAlert('', t('success.createSuccess'), 'success', '');
            } else {
                addAlert('', response.message || t('error.createError'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }

    //Dialog
    const [open, setOpen] = React.useState(false);
    const handleClickOpenDialog = () => {
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setOpen(false);
    };




    return (
        <div className='flex-col app-header-margin' style={{ gap: 24 }}>
            {/* wallet */}
            <Swiper
                slidesPerView={_.size(data.wallets) > 1 ? 2 : 1}
                spaceBetween={16}
                freeMode={true}
                modules={[FreeMode]}
                style={{ width: "100%" }}
            >
                {data.wallets && _.map(data.wallets, _wallet => {
                    return (
                        <SwiperSlide key={_wallet.id}>
                            <div className={styles.walletBoxStyle}>
                                <div className="dis-flex flex-t flex-l" style={{ flexDirection: 'column' }} >
                                    <div className="flex-c-m" style={{ gap: 8, flexDirection: 'row' }}>
                                        <Typography sx={{ color: '#101828', fontWeight: 'bold' }} variant="subtitle2" >{_wallet.name[i18n.resolvedLanguage]}</Typography>
                                        <ClickAwayListener onClickAway={() => hideWalletTooltip(_wallet.id)}>
                                            <Tooltip
                                                title={<WalletInfo info={_wallet} />}
                                                open={walletTooltip[_wallet.id] || false}
                                                onClose={() => hideWalletTooltip(_wallet.id)}
                                            >
                                                <div onClick={() => showWalletTooltip(_wallet.id)}>
                                                    <FiEye style={{ width: 14, height: 14 }} className={styles.iconHoverStyle} />
                                                </div>
                                            </Tooltip>
                                        </ClickAwayListener>
                                    </div>
                                    <Typography variant="body2" sx={{ color: '#667085' }}>{t('home.currentBalance')}</Typography>
                                </div>
                                <div>
                                    <Typography color="primary" variant="h6" sx={{ fontWeight: 'bold' }} >$ {currencyFormat(_wallet.balance, 2)}</Typography>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            {/* game */}
            <div className="dis-inline-flex blur-bg w-full p-all-16 w-full" style={{ gap: 16, flexDirection: 'column', borderRadius: 5, background: 'radial-gradient(#3a5580,#2e4264,#213245)' }}>

                <div className="flex-sb-m">
                    <div>
                        <Typography variant="body1">{t('home.mainPool')}</Typography>
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>$ {currencyFormat(data?.game_summary?.poolAmount)}</Typography>
                    </div>
                    <div style={{ paddingTop: 10 }}>
                        {
                            (accountSetting?.email === true && !email_verified_at) ?
                                <Button onClick={accessToken ? (player_id ? loginGame : handleClickOpenDialog) : () => navigate('/login')} variant='contained'>{!player_id ? t('button.createGameAccount') : t('button.playNow')}</Button>
                                :
                                <Button onClick={accessToken ? (player_id ? loginGame : createGameAccount) : () => navigate('/login')} color="primary" variant='contained'>{!player_id ? t('button.createGameAccount') : t('button.playNow')}</Button>
                        }
                    </div>
                    <VerifyEmailDialog open={open} handleCloseDialog={handleCloseDialog} />

                </div>
                <div className='w-full'>
                    <LinearProgress variant="determinate" value={Math.min((data?.game_summary?.listing?.[0]?.total_match || 0) / data?.game_required * 100, 100)} color="primary" />
                </div>
                <div>
                    <Typography className="txt-right">{data?.game_summary?.listing?.[0]?.total_match || 0}/{data?.game_required || 0} {t('title.game')}</Typography>
                </div>
            </div>

            {/* vip package */}
            <div className="p-all-16" style={{ borderRadius: 6, background: 'linear-gradient(90deg, #55D3FF,#684EFF)' }}>
                <div className="flex-sb-m w-full " >
                    <div className="flex-c-t" style={{ flexDirection: 'column', display: 'flex', width: '60%', gap: 8 }}>
                        <Typography variant='h6'>{t('home.vipPackage')}</Typography>
                        {/* {data?.user_packages === 0 ?
                        <Typography variant='body2' style={{ color: '#98A2B3' }}>{t('home.vipPackageDetail')}</Typography>
                        :
                        <Typography variant='body2' style={{ color: '#98A2B3' }}>{t('home.totalPackage')}: {data.user_packages}</Typography>
                    } */}
                        <Typography variant='body2' style={{ color: 'white' }}>{t('home.vipPackageDetail')}</Typography>
                    </div>
                    <div style={{ maxHeight: 110, maxWidth: 110 }}>
                        <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={data?.user_packages?.plan?.plan_logo?.file_name ? data.user_packages.plan.plan_logo.file_name : '../images/package/inactive-package.png'} alt="package" />
                    </div>
                </div>
                <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', width: 'fit-content' }}>
                    <Button color="primary" style={{ background: 'rgba(255, 255, 255, 0.85)', color: '#3379CB', width: 'fit-content' }} variant='contained' onClick={() => navigate("/packages/purchase")}>{t('button.buyPackage' /* 'button.buyPackage' */)}</Button>
                    <p className="fs-15 p-l-15 pointer" style={{ textDecoration: 'underline', alignSelf: 'center', color: 'white' }} onClick={() => navigate("/packages/histories", { state: { from: storePaths(location) } })}>{t('wallet.planHistories')}</p>
                </div>
            </div>

            {/* genealogy */}
            <div className="flex-sb-m dark-blue-bg w-full p-all-16" style={{ borderRadius: 6 }}>
                <div style={{ maxHeight: 130, maxWidth: 130 }}>
                    <img style={{ objectFit: 'cover' }} className='s-full' src={`/images/general/genealogy.png`} alt="genealogy" />
                </div>
                <div className="flex-c-t" style={{ flexDirection: 'column', display: 'flex', width: '50%', gap: 16 }}>
                    <Link component={RouterLink} to={`/community/sponsor`} state={{ from: storePaths(location) }} underline='none' className="dis-flex flex-row flex-l-m" style={{ gap: 8 }}>
                        <Typography>{t('genealogy.myDownline')}</Typography>
                        <FiChevronRight style={{ width: 18, height: 18 }} className={styles.iconStyle} />
                    </Link>
                    <div className="flex-sb-m" style={{ flexDirection: 'row', display: 'flex', width: '90%' }}>
                        <div className="dis-flex flex-col" style={{ gap: 8 }}>
                            {/* <Typography variant="body2">{t('genealogy.totalActive')}</Typography> */}
                            <Typography variant="body2">{t('genealogy.totalDirect')}</Typography>
                            <Typography color="primary" variant="subtitle2">{data?.downlines?.direct_sponsor || 0}</Typography>
                        </div>
                        <div className="dis-flex flex-col" style={{ gap: 8 }}>
                            {/* <Typography variant="body2">{t('genealogy.totalInactive')}</Typography> */}
                            <Typography variant="body2">{t('genealogy.total')}</Typography>
                            <Typography color="primary" variant="subtitle2">{data?.downlines?.all_sponsor || 0}</Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

const useStyles = makeStyles((theme) => ({
    iconHoverStyle: {
        color: '#667085',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    iconStyle: {
        color: '#FFFFFF',
    },
    walletBoxStyle: {
        flexDirection: 'column',
        borderRadius: 18,
        backdropFilter: 'blur(40px)',
        backgroundColor: '#F2F4F7',
        // width: '50%',
        gap: 16,
        padding: '21px 57px 21px 15px',
        display: 'flex',
        [theme.breakpoints.up('xs')]: {
            padding: 15,
        },
        [theme.breakpoints.up('sm')]: {
            padding: 15,
        },
    },
}));

export default Home;