import { storeProfile } from '@actions';
import { isUndefined } from 'lodash';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, putUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateImported } from '@actions';

//MUI
import { Alert, AlertTitle, Button, Grid, TextField, Typography, Badge, Avatar, Dialog, DialogTitle, DialogContent, ListItem, ListItemButton, List, ListItemText, DialogActions, ListItemIcon } from '@mui/material/';
import { FiImage } from 'react-icons/fi';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { HiOutlinePencil } from "react-icons/hi2";

import DeleteConfirmationModal from '@layouts/DeleteConfirmationModal';

export default function BasicInfoProfile() {
    const theme = useTheme();
    const styles = useStyles();
    const { t } = useTranslation();
    const { username, name, email, mobile, email_verified_at, profile_image, sub_email } = useSelector(state => state.user);
    const { accountSetting, importedUser } = useSelector(state => state.general);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        name: name,
        email: email,
        mobile: mobile,
        username: username,
    });
    const [option, setOption] = useState({
        showImageOption: false,
        previewImage: false,
        deleteConfirm: false,
    });
    const [imgState, setImgState] = useState({
        image: null,
        previewUrl: null,
    });
    const hiddenFileInput = useRef();
    // const [verifyButton, setVerifyButton] = useState(false);
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const submitData = (e) => {
        e.preventDefault();
        setLoading(true);
        setInputErrors();
        let apiData = {
            name: state.name,
            email: state.email,
            mobile: state.mobile,
        }

        putUrl(`/user`, apiData).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.data));
                dispatch(updateImported());
                addAlert('', t('success.editSuccess'), 'success', '');
                setState({
                    ...state,
                    existing_image: response.data.profile_picture,
                });
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    // const verifyEmail = () => {
    //     setLoading(true);
    //     setInputErrors();
    //     getUrl(`/user/send-email-verification-url/verify`).then(response => {
    //         setLoading(false);
    //         if (response.status) {
    //             setVerifyButton(true);
    //             addAlert('', response.message, 'success', '');
    //         } else {
    //             setInputErrors(response.errors);
    //             addAlert('', t('error.editError'), 'error', '');
    //         }
    //     }).catch(error => {
    //         setLoading(false);
    //         addAlert('', error.message || t('error.contactSupport'), 'error', '');
    //     });
    // }

    const openMenu = () => {
        setOption({ ...option, showImageOption: true });
    }

    const closeMenu = () => {
        setOption({ ...option, showImageOption: false });
    }

    const handleImageChange = ({ target }) => {
        setImgState({ ...state, image: target.files[0] });
        if (!isUndefined(target.files[0])) {
            setOption({ ...option, showImageOption: false, previewImage: true });
        }
        target.value = null;
    }

    useEffect(() => {
        if (state.image) {
            setImgState({ ...state, previewUrl: URL.createObjectURL(state.image) })
        } else {
            setImgState({ ...state, previewUrl: null });
        }
    }, [state.image]);

    useEffect(() => {
        setState({ ...state, username: username, name: name, email: email, mobile: mobile });
        // eslint-disable-next-line
    }, [username, name, email, mobile])

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const closePreview = () => {
        setOption({ ...option, previewImage: false });
    }

    const saveProfileImage = () => {
        setInputErrors({});
        closePreview();
        setLoading(true);
        const formData = new FormData();
        formData.append("_method", "put");
        formData.append("profile_image", state.image);

        postUrl('/user/upload-profile-image', formData).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.data));
                addAlert('', t('success.imageUploadSuccess'), 'success', '');
                setImgState({
                    ...imgState,
                    image: null,
                    previewUrl: null,
                });
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.imageUploadFailed'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const removeProfileImage = () => {
        setLoading(true);
        if (profile_image) {
            putUrl(`/user/${profile_image.id}/delete-profile-image`).then(response => {
                setLoading(false);
                if (response.status) {
                    dispatch(storeProfile(response.data));
                    addAlert('', t('success.imageUploadSuccess'), 'success', '');
                    setImgState({
                        ...imgState,
                        image: null,
                        previewUrl: null,
                    });
                    setOption({ ...option, deleteConfirm: false });
                    addAlert('', t('success.imageRemoveSuccess'), 'success', '');
                } else {
                    addAlert('', t('error.imageRemoveFailed'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            addAlert('', t('error.contactSupport'), 'error', '');
        }
    }

    const resendEmailUpdateVerification = () => {
        setLoading(true);
        getUrl(`/user/send-email-verification-url/update`).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div className='app-header-margin flex-col flex-m p-t-10'>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                {
                    state?.email == null &&
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            <AlertTitle style={{ color: 'red', fontWeight: 'bold' }}>{t('general.warning')}</AlertTitle>
                            <Typography style={{ color: 'black', textAlign: 'left' }} variant='body2' >{t('user.userEmailWarning')}</Typography>
                        </Alert>
                    </Grid>
                }
                {
                    importedUser &&
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            <AlertTitle style={{ color: 'red', fontWeight: 'bold' }}>{t('general.warning')}</AlertTitle>
                            <Typography style={{ color: 'black', textAlign: 'left' }} variant='body2' >{t('user.changeEmailWarning')}</Typography>
                        </Alert>
                    </Grid>
                }
                <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" style={{ gap: 6 }} >
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    // badgeContent={
                    //     <Avatar alt="Edit" className={styles.smallAvatarStyle} onClick={openMenu} ><HiOutlinePencil style={{ color: 'white', height: 15, width: 15, cursor: 'pointer' }} /></Avatar>
                    // }
                    >
                        {
                            profile_image ?
                                <Avatar className={styles.avatarStyle} src={profile_image?.file_name} />
                                :
                                (
                                    name ?
                                        <Avatar className={styles.avatarStyle}>
                                            <Typography variant='h3'>{name.charAt(0).toUpperCase()}</Typography>
                                        </Avatar>
                                        :
                                        <Avatar className={styles.avatarStyle} />
                                )
                        }
                    </Badge>
                    {
                        inputErrors?.profile_image &&
                        <Typography style={{ color: 'red', paddingTop: 2 }}>{inputErrors?.profile_image}</Typography>
                    }
                    <Typography variant='h6' style={{ textAlign: 'center', wordBreak: 'break-all' }} >{name || ''}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" style={{ gap: 6, marginTop: 10 }}>
                <Grid item xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        name='username'
                        label={t('user.username')}
                        placeholder={t('user.username')}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                        error={inputErrors && inputErrors.username ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        disabled
                        value={state.username || ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        name='name'
                        label={t('user.name')}
                        placeholder={t('user.name')}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                        error={inputErrors && inputErrors.name ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        value={state.name || ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        name='email'
                        label={t('user.email')}
                        placeholder={t('user.email')}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                        error={inputErrors && inputErrors.email ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        value={state.email || ''}
                    // disabled={!importedUser}
                    />
                </Grid>
                {sub_email ?
                    <Grid item xs={12} style={{ textAlign: 'left', margin: '0 0' }}>
                        <Alert severity="info">
                            <AlertTitle style={{ color: 'black', fontWeight: 'bold' }}>{t('general.info')}</AlertTitle>
                            <Typography style={{ color: 'black', textAlign: 'left', fontWeight: 'bold', wordBreak: 'break-all' }} variant='body2' >{t('user.subEmail') + ': ' + sub_email}</Typography>
                            <Typography style={{ color: 'black', textAlign: 'left' }} variant='body2' >{t('user.verifyEmail')}</Typography>
                            <Button variant="contained" size="small" onClick={resendEmailUpdateVerification} style={{ color: '#fff', marginTop: 15, width: 'fit-content' }}>{t('user.resendVerificationLink')}</Button>
                        </Alert>
                    </Grid>
                    : null}
                {/* {
                    (accountSetting?.email === true && email_verified_at === null) ?
                        <Grid item xs={12}>
                            <Button variant="contained" disabled={verifyButton} onClick={verifyEmail} >{t('button.verify')}</Button>
                        </Grid>
                        : null
                } */}
                <Grid item xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        name='mobile'
                        label={t('user.mobile')}
                        placeholder={t('user.mobile')}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                        error={inputErrors && inputErrors.mobile ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        value={state.mobile || ''}
                    />
                </Grid>
                <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" >
                    <Button variant="contained" onClick={submitData} style={{ paddingTop: 10, paddingBottom: 10 }}>{t('button.save')}</Button>
                </Grid>
            </Grid>

            {/* upload image */}
            <input accept="image/*" className={styles.input} type="file" onChange={handleImageChange} ref={hiddenFileInput} />
            <Dialog open={option?.showImageOption} onClose={closeMenu}>
                <DialogTitle style={{ paddingTop: 40 }}>{t('dialog.imageOptionTitle')}</DialogTitle>
                <DialogContent style={{ padding: '0 24px 10px', width: '100%' }}>
                    <List className='flex-c-m' style={{ backgroundColor: theme.palette.dialogContentBg, borderRadius: 12, border: '1px dashed #ffffff3b' }}>
                        <ListItem>
                            <ListItemButton onClick={handleClick} className='flex-col' style={{ padding: '20px 40px' }}>
                                <ListItemIcon style={{ minWidth: 'unset', paddingBottom: 10 }}>
                                    <FiImage className="img-style" />
                                </ListItemIcon>
                                <ListItemText primary={t('dialog.chooseNewImage')} />
                            </ListItemButton>
                        </ListItem>
                        {
                            profile_image &&
                            <ListItem>
                                <ListItemButton onClick={() => setOption({ ...option, showImageOption: false, deleteConfirm: true })}>
                                    <ListItemIcon>
                                        <RiDeleteBin7Line className="img-style" />
                                    </ListItemIcon>
                                    <ListItemText primary={t('dialog.deleteProfileImage')} />
                                </ListItemButton>
                            </ListItem>
                        }
                    </List>
                </DialogContent>
                <DialogActions style={{ width: 'calc(100% - 39px)', paddingBottom: 40 }}>
                    <Button variant="contained" onClick={closeMenu} style={{ paddingTop: 10, paddingBottom: 10 }}>{t('button.close')}</Button>
                </DialogActions>
            </Dialog>
            {/* preview image */}
            <Dialog open={option?.previewImage} onClose={closePreview} >
                <DialogTitle style={{ paddingTop: 40 }}>{t('dialog.imageOptionTitle')}</DialogTitle>
                <DialogContent style={{ padding: '0 24px 10px', width: '100%' }}>
                    <List style={{ backgroundColor: theme.palette.dialogContentBg, borderRadius: 12, padding: 10, border: '1px dashed #ffffff3b' }}>
                        <ListItem style={{ justifyContent: 'center', padding: '20px 40px' }}>
                            <Avatar className={styles.avatarStyle} src={state.previewUrl} />
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions style={{ width: 'calc(100% - 39px)', paddingBottom: 40 }}>
                    <Button variant="outlined" onClick={closePreview} style={{ paddingTop: 10, paddingBottom: 10 }}>{t('button.cancel')}</Button>
                    <Button variant="contained" onClick={saveProfileImage} style={{ paddingTop: 10, paddingBottom: 10 }}>{t('button.save')}</Button>

                </DialogActions>
            </Dialog>
            <DeleteConfirmationModal open={option.deleteConfirm} closeDeleteDialog={() => setOption({ ...option, deleteConfirm: false })} submitData={removeProfileImage} />
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    avatarStyle: {
        height: 96,
        width: 96,
        flexShrink: 0,
    },
    smallAvatarStyle: {
        width: 34,
        height: 34,
        border: `1px solid ${theme.palette.white}`,
        backgroundColor: '#EF4B27',
        cursor: 'pointer',
        flexShrink: 0,
    },
    input: {
        display: "none",
    },
}));