import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

// MUI
import { Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { FiEye, FiEyeOff } from "react-icons/fi";

import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';

export default function ResetPassword() {
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const [searchParams] = useSearchParams();

    const [state, setState] = useState({
        email: '',
        password: '',
        passwordConfirmation: '',
        token: searchParams.get("token")
    });

    const [option, setOption] = useState({
        password_show: false,
        password_confirmation_show: false,
    });

    const [inputErrors, setInputErrors] = useState();
    const { addAlert, setLoading } = useNotificationLoading();
    const [success, setSuccess] = useState(false);
    let navigate = useNavigate();

    const resetPassword = () => {
        setLoading(true);
        let params ={
            email: state.email,
            password: state.password,
            password_confirmation: state.passwordConfirmation,
            token: searchParams.get("token")
        }
        postUrl('/reset-password', params).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                setSuccess(true);
            } else {
                setInputErrors(response.errors);
                let msg = response.message;
                _.map(response.errors, (value, index) => {
                    msg += ` ${value}`;
                })
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handlePasswordShow = (name) => {
        setOption({ ...option, [name]: !option[name] });
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        success === true ?
            <Box className={styles.marginTop100} style={{ height: '100vh', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }} >
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12}   >
                        <div className='flex-col-c-m'>
                            <img src="images/background/Double_Star.png" style={{ marginTop: 60, height: '10vh' }} alt="logo" />
                            <Typography style={{ textAlign: 'center', fontSize: 30, fontWeight: 600 }}>{t('resetPassword.resetSuccess')}</Typography>
                            <Typography style={{ textAlign: 'center', fontSize: 16 , width:'250px'}}>{t('resetPassword.resetSuccessSubline')}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" style={{ marginTop: 20 }} >
                        <Button variant="contained" onClick={() => navigate("/login")} fullWidth >{t('general.backToLogin')}</Button>
                    </Grid>
                </Grid>
            </Box>
            :
            <Box className={styles.marginTop100} style={{ height: '100vh', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }} >
                {/* <center><img src="images/logos/logo_white_brand_full.png" style={{ height: 100, margin: '10px 0 20px' }} alt="logo" /></center> */}
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={styles.title} style={{ textAlign: 'justify' }}>{t('resetPassword.title')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ textAlign: 'justify' }} className={styles.desc} gutterBottom>{t('resetPassword.desc')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Typography>{t('user.email')}</Typography>
                        </div>
                        <div style={{ borderRadius: 30 }}>
                            <TextField
                                variant="outlined"
                                placeholder={t('user.email')}
                                fullWidth
                                type="email"
                                autoComplete="email"
                                color='primary'
                                value={state.email}
                                onChange={({ target }) => setState({ ...state, email: target.value })}
                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                error={inputErrors && inputErrors.email ? true : false}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Typography>{t('user.password')}</Typography>
                        </div>
                        <div style={{ borderRadius: 30 }}>
                            <TextField
                                variant="outlined"
                                placeholder={t('user.password')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handlePasswordShow('password_show')}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {option?.password_show ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                autoComplete="password"
                                color='primary'
                                value={state.password}
                                type={option?.password_show ? 'text' : 'password'}
                                onChange={({ target }) => setState({ ...state, password: target.value })}
                                helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                                error={inputErrors && inputErrors.password ? true : false}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Typography>{t('user.confirmPassword')}</Typography>
                        </div>
                        <div style={{ borderRadius: 30 }}>
                            <TextField
                                variant="outlined"
                                placeholder={t('user.confirmPassword')}
                                type={option?.password_confirmation_show ? 'text' : 'password'}
                                InputProps={{

                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handlePasswordShow('password_confirmation_show')}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {option?.password_confirmation_show ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                autoComplete="password"
                                color='primary'
                                value={state.passwordConfirmation}
                                onChange={({ target }) => setState({ ...state, passwordConfirmation: target.value })}
                                helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                                error={inputErrors && inputErrors.password_confirmation ? true : false}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Button variant="contained" color="primary" size="large" onClick={resetPassword} fullWidth >{t('button.reset')}</Button>
                    </Grid>
                </Grid>
            </Box>
    );
}

const useStyles = makeStyles(theme => ({
    marginTop100:{
        marginTop:100,
        padding:'0px 10% 0px 10%'
    },
    title :{
        fontSize:30,
        fontWeight:700,
        letterSpacing:'-0.3px',
        lineHeight:'130%'
    },
    desc :{
        fontSize:16,
        fontWeight:400,
        lineHeight:'125%',
    }
}));