import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { storeProfile } from '@actions';

import { Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

export default function CryptoAddress() {
    const { crypto_address } = useSelector(state => state.user);
    const [state, setState] = useState({
        crypto_address,
        securityPassword: "",
    });
    const [showPassword, setShowPassword] = useState({
        securityPassword: false,
    });

    const theme = useTheme();
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const updateCryptoAddress = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            crypto_address: state.crypto_address,
            security_password: state.securityPassword
        }
        putUrl(`/user/crypto-address`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, securityPassword: "" });
                dispatch(storeProfile(response.data));
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div className='app-header-margin p-t-10' style={{   gap: 24 }}>
            <div className='p-b-24'>
                <TextField
                    variant="standard"
                    fullWidth
                    name="crypto_address"
                    label={t('user.cryptoAddress')}
                    placeholder={t('user.cryptoAddress')}
                    type='text'
                    value={state.crypto_address || ''}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.crypto_address ? inputErrors.crypto_address : ''}
                    error={inputErrors && inputErrors.crypto_address ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        disableUnderline: true
                    }}
                />
            </div>

            <div className='p-b-24'>
                <TextField
                    variant="standard"
                    fullWidth
                    name="securityPassword"
                    label={t('user.securityPassword')}
                    placeholder={t('user.securityPassword')}
                    type={showPassword.securityPassword ? 'text' : 'password'}
                    value={state.securityPassword || ''}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                    error={inputErrors && inputErrors.security_password ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => handleClickShowPassword('securityPassword')}
                                // onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword.securityPassword ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className='p-t-24'>
                <Button variant="contained" onClick={updateCryptoAddress} style={{ paddingTop: 10, paddingBottom: 10 }} fullWidth>{t('button.save')}</Button>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
}));