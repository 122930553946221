import { Box, Dialog, DialogContent, DialogTitle, Grid, Slide } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl } from '@utils/ApiAction';
import { currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MdOutlineKeyboardArrowRight } from "react-icons/md";

//Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PlanHistories() {
    const [state, setState] = useState({
        data: [],
        profitLimit: [],
        lockedCredit: [],
    });

    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    //Dialog
    const [dialog, setDialog] = useState({
        open: false,
        data: []
    });
    const handleClickOpen = (data) => {
        setDialog({ ...dialog, open: true, data: data });
    };
    const handleClose = () => {
        setDialog({ ...dialog, open: false, data: [] });
    };

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl('/user/plan-histories').then(response => {
            if (response.status && isMountedRef.current) {
                setState({ ...state, data: response.data.plan_histories, profitLimit: response.data.total_credit_limit, lockedCredit: response.data.locked_roi });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [state.pageIndex]);

    return (
        <div className='p-b-150' style={{ minHeight: '100vh', overflowY: 'hidden' }}>
            <Box style={{ backgroundColor: '#121B3E', paddingLeft: 15, paddingRight: 15 }}>
                <div className='p-b-20'>
                    <p style={{ paddingLeft: 32, fontFamily: 'Nunito', fontSize: 16, color: 'white', 'opacity': 0.65 }}>{t('package.availableCredit')}</p>
                    <div className='flex-sb-m p-lr-32' style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ paddingTop: 10, fontSize: 33, color: 'white', fontWeight: 'bold', opacity: 0.8 }}>$ <span style={{ fontFamily: 'Nunito' }}>{currencyFormat(state.profitLimit.profit_limit_balance || 0.00)}{" / " + currencyFormat(state.profitLimit.profit_limit || 0.00)}</span></div>
                    </div>
                    {
                        _.size(state.lockedCredit) > 0 ?
                            <>
                                <p style={{ paddingTop: 10, paddingLeft: 32, fontFamily: 'Nunito', fontSize: 14, color: 'white', 'opacity': 0.65 }}>{t('package.lockedROI')}</p>
                                {
                                    _.map(state.lockedCredit, (detail, index) => {
                                        return (
                                            <div key={index}>
                                                <div className='flex-sb-m p-lr-32' style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <div style={{ fontSize: 32, color: 'white', opacity: 0.8 }}>{'$ ' + currencyFormat(detail.locked || 0.00) + ' '}<span style={{ fontFamily: 'Nunito', fontSize: 18 }}>{(i18n.resolvedLanguage ? detail?.wallet?.name[i18n.resolvedLanguage] : detail?.wallet?.name['en'])}</span></div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                            : null
                    }
                </div>
                <div className='pos-relative'>
                    <div style={{ background: '#fff8', height: 1, width: '100%' }} />
                    <div style={{ background: '#fff', height: 4, width: 30, marginTop: -2, marginLeft: 40 }} />
                </div>
                {
                    _.size(state.data) > 0 ?
                        <>
                            {
                                _.map(state.data, (detail, index) => {
                                    return (
                                        <div key={index} style={{ padding: '16px 10px', borderTop: index !== 0 ? '3px solid #202E63' : '' }} onClick={() => handleClickOpen(detail)} className='pointer'>
                                            <Grid container display='flex' justifyContent='space-between'>
                                                <Grid item>
                                                    {
                                                        detail?.plan?.plan_logo?.file_name ?
                                                            <img style={{ width: '40px', height: '40px', objectFit: 'cover' }} src={detail.plan.plan_logo.file_name} alt="package" />
                                                            : null
                                                    }
                                                    <p className='txt-white fs-16' style={{ fontWeight: 600, alignSelf: 'center' }}>
                                                        {detail?.plan_name[i18n.resolvedLanguage] || detail?.plan_name['en']}
                                                        <span className='txt-white fs-16' style={{ textAlign: 'center', color: '#55D3FF', borderRadius: '5px', marginLeft: 10, paddingLeft: 10, paddingRight: 10 }}>{(detail?.details?.unlocked == true ? t('package.complete') : '')}</span>
                                                    </p>

                                                </Grid>
                                                <Grid item style={{ color: theme.palette.primary.main, fontWeight: 600, fontSize: 16, alignSelf: 'center' }}>
                                                    <p style={{ textAlign: 'right' }}><MdOutlineKeyboardArrowRight style={{ height: '30px', width: 'auto' }} /></p>
                                                    <>{currencyFormat(detail.price * 1, 2)}</>
                                                </Grid>
                                            </Grid>
                                            <Grid container display='flex' justifyContent='space-between' className='p-t-5'>
                                                <Grid item className='fs-12 txt_truncate2' style={{ color: '#B6B8C3' }}>{t('wallet.planId') + ': '}{detail.ulid}</Grid>
                                                <Grid item className='fs-12' style={{ color: '#B6B8C3' }}>{detail.created_at}</Grid>
                                            </Grid>
                                            <Grid container display="flex" flexDirection="column" justifyContent="space-between" style={{ padding: 5, color: '#B6B8C3' }}>
                                                <p>{t('package.dailyMission')}</p>
                                                {/* <p style={{ paddingTop: 3 }}>{detail?.plan?.extra_setting?.min_match + ' ' + t('package.missionRequirement') + ' ' + detail?.plan?.extra_setting?.min_amount}</p> */}
                                            </Grid>
                                            {
                                                detail?.daily_mission == true ?
                                                    <Grid container display="flex">
                                                        <Grid item xs={12}>
                                                            <progress value={1} style={{ width: "100%" }} />
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    <Grid container display="flex">
                                                        <Grid item xs={10}>
                                                            <progress value={detail?.daily_mission_count / detail?.plan?.extra_setting?.min_match} style={{ width: "100%" }} />
                                                        </Grid>
                                                        <Grid item xs={2} className='fs-16' style={{ color: '#B6B8C3', textAlign: 'center' }}>{detail?.daily_mission_count + ' / ' + detail?.plan?.extra_setting?.min_match}</Grid>
                                                    </Grid>
                                            }
                                            <Grid container display="flex" flexDirection="column" justifyContent="space-between" style={{ padding: 5, color: '#B6B8C3' }}>
                                                <p>{t('package.monthlyMission') + ` (${t('package.starts')} ` + (detail.roi.start_date || '-') + ')'}</p>
                                                {/* <p style={{ paddingTop: 3 }}>{detail?.plan?.extra_setting?.min_match + ' ' + t('package.missionRequirement') + ' ' + detail?.plan?.extra_setting?.min_amount + ` (30${t('package.days')})`}</p> */}
                                            </Grid>
                                            {
                                                detail?.monthly_mission == true ?
                                                    <Grid container display="flex" style={{ paddingTop: 5 }}>
                                                        <Grid item xs={12}>
                                                            <progress value={1} style={{ width: "100%" }} />
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    <Grid container display="flex">
                                                        <Grid item xs={10}>
                                                            <progress value={detail?.roi?.mission_count / 30} style={{ width: "100%" }} />
                                                        </Grid>
                                                        <Grid item xs={2} className='fs-16' style={{ color: '#B6B8C3', textAlign: 'center' }}>{detail?.roi?.mission_count + ` / 30`}</Grid>
                                                    </Grid>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </>
                        :
                        <div className='txt-center' style={{ paddingTop: 40 }}>
                            <img src='/images/general/no-transaction.png' alt='no-transaction' style={{ width: 152, height: 118 }} loading='lazy' />
                            <p className='txt-white fs-16' style={{ paddingTop: 16, fontWeight: 600 }}>{t('table.noRecord')}</p>
                        </div>
                }
                <Dialog
                    open={dialog.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                >
                    <DialogTitle className='w-full fs-15 txt-center txt-upper' style={{ borderBottom: '2px dashed #fff3', fontWeight: 'bold', paddingBottom: 20, lineHeight: 1.1 }}>
                        {_.includes(i18n.resolvedLanguage, dialog?.data?.plan_name) ? dialog?.data?.plan_name[i18n.resolvedLanguage] : dialog?.data?.plan_name?.['en']}
                        <br />
                        <span className='fs-18 txt-title p-t-10' style={{ fontWeight: 'normal', color: '#9ba9c7' }}>{t('wallet.transactionDetails')}</span>
                    </DialogTitle>
                    <DialogContent className='txt-white fs-14 lh-12 w-full'>
                        {
                            dialog.data?.plan?.plan_logo?.file_name ?
                                <div style={{ textAlign: 'center' }}>
                                    <img style={{ width: '110px', height: '110px', objectFit: 'cover' }} src={dialog.data.plan.plan_logo.file_name} alt="package" />
                                </div>
                                : null
                        }
                        <div className='p-tb-15'>
                            <div className='p-b-20'>
                                <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.transactionId')}:</p>
                                <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{dialog.data.ulid}</p>
                            </div>
                            <div className='p-b-20'>
                                <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.dateTime')}</p>
                                <p className='fs-15' style={{ fontWeight: 'bold' }}>{dialog?.data?.created_at}</p>
                            </div>
                            <div className='p-b-20'>
                                <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.status')}</p>
                                <p className='fs-15' style={{ fontWeight: 'bold' }}>{_.includes(i18n.resolvedLanguage, dialog?.data?.status_display) ? dialog?.data?.status_display[i18n.resolvedLanguage] : dialog?.data?.status_display?.['en']}</p>
                            </div>
                            <div className='p-b-20'>
                                <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.profitLimit')}</p>
                                <p className='fs-15' style={{ fontWeight: 'bold' }}>{dialog?.data?.profit_limit_balance + ' / ' + dialog?.data?.profit_limit}</p>
                            </div>
                            <div className='p-b-20'>
                                <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('package.dailyMission')}</p>
                                <p className='fs-15' style={{ fontWeight: 'bold' }}>{dialog?.data?.plan?.extra_setting?.min_match + ' ' + t('package.missionRequirement') + ' ' + dialog?.data?.plan?.extra_setting?.min_amount}</p>
                            </div>
                            <div className='p-b-20'>
                                <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('package.monthlyMission')}</p>
                                <p className='fs-15' style={{ fontWeight: 'bold' }}>{dialog?.data?.plan?.extra_setting?.min_match + ' ' + t('package.missionRequirement') + ' ' + dialog?.data?.plan?.extra_setting?.min_amount + ` (30${t('package.days')})`}</p>
                            </div>
                        </div>
                        <div className='p-t-20 flex-sb-m' style={{ borderTop: '2px dashed #fff3' }}>
                            <p className='p-b-5 txt-upper' style={{ color: '#fff', whiteSpace: 'break-spaces' }}>{t('wallet.totalAmount')}</p>
                            <p style={{ fontWeight: 'bold', wordBreak: 'break-all', textAlign: 'right' }} className='fs-21'>USD <br /> {currencyFormat((dialog.data?.price || 0) * 1, 4)}</p>
                        </div>
                    </DialogContent>
                </Dialog>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
}));