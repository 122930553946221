import { Button, Grid } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { currencyFormat } from '@utils/Tools';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { BiDollarCircle } from "react-icons/bi";
import { MdAddCard, MdOutlineVideogameAsset, MdSend } from "react-icons/md";
import { storePaths } from '@utils/Tools';

import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import { FiEye } from 'react-icons/fi';
import 'swiper/css/bundle';

export default function Wallet(props) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { state, setState } = props;
    const [slideView, setSlideView] = useState(0);

    useEffect(() => {
        let actionCount = 0;
        if (_.includes(state.walletSetting?.['deposit-rules']?.wallet_ids, state.wallets[state.walletIndex]?.id)) {
            actionCount++;
        }
        if (_.includes(state.walletSetting?.['game-topup-rules']?.wallet_ids, state.wallets[state.walletIndex]?.id)) {
            actionCount++;
        }
        if (_.find(state.walletSetting['transfer-rules'], state.wallets[state.walletIndex]?.id)) {
            actionCount++;
        }
        if (_.includes(state.walletSetting?.['withdraw-rules']?.wallet_ids, state.wallets[state.walletIndex]?.id)) {
            actionCount++;
        }
        setSlideView(actionCount >= 4 ? 3.3 : actionCount);
    }, [state.walletIndex, state.walletSetting, state.wallets])

    return (
        <Grid container display='flex' flexDirection='column' alignContent='center' justifyContent='center' textAlign='center'>
            <Grid item xs={12}>
                <Swiper
                    slidesPerView={1}
                    navigation={true}
                    modules={[Navigation]}
                    onSlideChange={(e) => setState({ ...state, walletIndex: e.activeIndex })}
                >
                    {
                        _.size(state.wallets) > 0 && _.map(state.wallets, (detail, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div>
                                        <p className='txt-white fs-18 inter-normal'>{detail.name[i18n.resolvedLanguage]}
                                            {
                                                state.walletDecimal === 2 ?
                                                    <FiEye style={{ width: 14, height: 14, color: '#ffffff', cursor: 'pointer', paddingLeft: 10, position: 'absolute' }} onClick={() => setState({ ...state, walletDecimal: 4 })} />
                                                    :
                                                    <FiEye style={{ width: 14, height: 14, color: theme.palette.primary.main, cursor: 'pointer', paddingLeft: 10, position: 'absolute' }} onClick={() => setState({ ...state, walletDecimal: 2 })} />
                                            }
                                        </p>
                                        <p className='txt-white fs-24 poppins-semibold p-t-8'>$ {currencyFormat(detail.balance * 1, state.walletDecimal)}</p>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </Grid>
            {
                (_.size(state.wallets) > 0 && state.walletSetting) ?
                    <Grid container display='flex' flexDirection='row' alignContent='center' justifyContent='center' textAlign='center' flexWrap='nowrap' pt={4}>
                        <Swiper
                            slidesPerView={slideView}
                            pagination={true}
                            style={{ width: '100%' }}
                        >
                            {
                                _.includes(state.walletSetting?.['deposit-rules']?.wallet_ids, state.wallets[state.walletIndex]?.id) ?
                                    <SwiperSlide>
                                        <Button color="primary" variant='contained' className={styles.funcButton} onClick={() => navigate('/wallet/deposit', { state: { from: storePaths(location) } })} >
                                            <MdAddCard style={{ height: 30, width: 'auto' }} />
                                        </Button>
                                        <p className='txt-white p-t-10 fs-14 inter-normal'>{t('wallet.deposit')}</p>
                                    </SwiperSlide>
                                    : null
                            }
                            {
                                _.includes(state.walletSetting?.['game-topup-rules']?.wallet_ids, state.wallets[state.walletIndex]?.id) ?
                                    <SwiperSlide>
                                        <Button color="primary" variant='contained' className={styles.funcButton} onClick={() => navigate(`/wallet/game-topup/${state.wallets[state.walletIndex].id}`, { state: { from: storePaths(location) } })}>
                                            <MdOutlineVideogameAsset style={{ height: 30, width: 'auto' }} />
                                        </Button>
                                        <p className='txt-white p-t-10 fs-14 inter-normal'>{t('wallet.gameTopup')}</p>
                                    </SwiperSlide>
                                    : null
                            }
                            {
                                _.find(state.walletSetting['transfer-rules'], state.wallets[state.walletIndex]?.id) ?
                                    <SwiperSlide>
                                        <Button color="primary" variant='contained' className={styles.funcButton} onClick={() => navigate(`/wallet/transfer/${state.wallets[state.walletIndex].id}`, { state: { from: storePaths(location) } })}>
                                            <MdSend style={{ height: 30, width: 'auto' }} />
                                        </Button>
                                        <p className='txt-white p-t-10 fs-14 inter-normal'>{t('wallet.transfer')}</p>
                                    </SwiperSlide>
                                    : null
                            }
                            {
                                _.includes(state.walletSetting?.['withdraw-rules']?.wallet_ids, state.wallets[state.walletIndex]?.id) ?
                                    <SwiperSlide>
                                        <Button color="primary" variant='contained' className={styles.funcButton} onClick={() => navigate(`/wallet/withdrawal/${state.wallets[state.walletIndex].id}`, { state: { from: storePaths(location) } })}>
                                            <BiDollarCircle style={{ height: 30, width: 'auto' }} />
                                        </Button>
                                        <p className='txt-white p-t-10 fs-14 inter-normal'>{t('wallet.withdraw')}</p>
                                    </SwiperSlide>
                                    : null
                            }
                        </Swiper>
                    </Grid>
                    : null
            }
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    funcButton: {
        background: 'linear-gradient(90deg, #55D3FF,#684EFF)',
        padding: 2,
        marginX: 10,
        width: 64,
        height: 64,
        flexDirection: 'column',
        zIndex: 1,
        borderRadius: '100%',
        textAlign: 'center',
        justifyContent: 'center',
        color: '#ffffff'
    }
}));