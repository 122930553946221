import React, { memo, useState, useEffect } from 'react';
import { public_restricted_routes, public_routes, private_routes, imported_user_restricted_routes } from '@configs/Router';
import _ from 'lodash';
import { removeLoginAccess } from '@utils/ApiAction';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, makeStyles } from '@mui/styles';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Tooltip, Avatar, Link } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate, matchRoutes , createSearchParams, useParams } from 'react-router-dom';
import { IoLanguage } from "react-icons/io5";
import { FiChevronLeft } from "react-icons/fi";

import { changeLanguage } from '@actions';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import moment from 'moment-timezone';
import { reducePaths,storePaths } from '@utils/Tools';

const systemLanguages = ['en', 'cn', 'ms', 'bn', 'es', 'hi', 'id', 'jp', 'kr', 'lo', 'my', 'pt', 'ru', 'si', 'ta', 'th', 'tl', 'tr', 'ur', 'vi'];

const without_back = ['/reset-password'];
const TRANSPARENT_PAGE = ['/login', '/register'];
const GO_BACK = ['/forgot-password', '/reset-password'];
const DARK_COLOR = ['/wallets/wallet', '/wallets/commission'];
const INITIAL_STATE = { showTutorBtn: false, showBackBtn: false, backUrl: null, tutorUrl: null, showLogo: true};

const TitleBar = memo((props) => {
   
    const theme = useTheme();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const [option, setOption] = useState(INITIAL_STATE);
    const [anchorEl, setAnchorEl] = useState(null);
    const { accessToken } = useSelector(state => state.general);
    const { email, name } = useSelector(state => state.user);
    const open = anchorEl?.attributes?.['data-ref']?.value;//Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event = null) => {
        if (event) event.stopPropagation()
        setAnchorEl(null);
    };
    let location = useLocation();
    let navigate = useNavigate();
    let from = location.state?.from?.pathname;
    let current_path = location?.pathname;
    let { id } = useParams();

    const [serverTime, setServerTime] = useState("");

    useEffect(() => {
        // let public_restricted_detail = _.find(public_restricted_routes, { 'path': current_path });
        // let public_detail = _.find(public_routes, { 'path': current_path });
        // let private_detail = _.find(private_routes, { 'path': current_path });

        let public_restricted_detail = matchRoutes(public_restricted_routes, current_path)?.[0]?.['route'];
        let public_detail = matchRoutes(public_routes, current_path)?.[0]?.['route'];
        let private_detail = matchRoutes(private_routes, current_path)?.[0]?.['route'];
        // let user_restricted_detail = matchRoutes(imported_user_restricted_routes, current_path)?.[0]?.['route'];

        if (_.size(public_restricted_detail) > 0) {
            setOption({ ...option, showBackBtn: public_restricted_detail?.showBackBtn, showTutorBtn: public_restricted_detail?.showTutorBtn, showLogo: public_restricted_detail?.showLogo, tutorUrl: public_restricted_detail?.tutorUrl, backUrl: public_restricted_detail?.backUrl, backTitle: t(public_restricted_detail?.backTitle) });
        } else if (_.size(public_detail) > 0) {
            setOption({ ...option, showBackBtn: public_detail?.showBackBtn, showTutorBtn: public_detail?.showTutorBtn, showLogo: public_detail?.showLogo, tutorUrl: public_detail?.tutorUrl, backUrl: public_detail?.backUrl, backTitle: t(public_detail?.backTitle) });
        } else if (_.size(private_detail) > 0) {
            setOption({ ...option, showBackBtn: private_detail?.showBackBtn, showTutorBtn: private_detail?.showTutorBtn, showLogo: private_detail?.showLogo, tutorUrl: private_detail?.tutorUrl, backUrl: private_detail?.backUrl, backTitle: t(private_detail?.backTitle) });
        } 
        // else if (_.size(user_restricted_detail) > 0) {
        //     setOption({ ...option, showExtendMargin: user_restricted_detail?.showExtendMargin, showBackBtn: user_restricted_detail?.showBackBtn, showTutorBtn: user_restricted_detail?.showTutorBtn, showLogo: user_restricted_detail?.showLogo, tutorUrl: user_restricted_detail?.tutorUrl, backUrl: user_restricted_detail?.backUrl, backTitle: t(user_restricted_detail?.backTitle) });}
            else{
            setOption(INITIAL_STATE);
        }

    }, [current_path,t]);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime(moment().format("YYYY-MM-DD HH:mm:ss"))
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    useEffect(() => {
        if (accessToken) {
            // if(email === null){
            //     navigate('/me/profile');
            // }
        }
        // eslint-disable-next-line
    }, [accessToken]);

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
        handleClose();
    };

    useEffect(() => {
        if(i18n.language == "ur"){
            document.documentElement.dir = 'rtl'
        }else{
            document.documentElement.dir = 'ltr';
        }
        document.documentElement.lang = i18n.language;
        // eslint-disable-next-line
    }, [i18n.language]);

    const getTitle = () => {
        let public_restricted_detail = _.find(public_restricted_routes, { 'path': current_path });
        let public_detail = _.find(public_routes, { 'path': current_path });
        let private_detail = _.find(private_routes, { 'path': current_path });

        let current_title = null;

        if (_.size(public_restricted_detail) > 0) {
            current_title = t(public_restricted_detail?.title);
        } else if (_.size(public_detail) > 0) {
            current_title = t(public_detail?.title);
        } else if (_.size(private_detail) > 0) {
            current_title = t(private_detail?.title);
        }

        return current_title;
    }

    const goBack = () =>{
        if (_.size(location?.state?.from) > 0) {
            let path = _.size(location?.state?.from) > 0 ? _.last(location?.state?.from) : '/';
            let pathListing= reducePaths(location);
            let urlLink = path?.pathname;
            if(path?.search !== ''){
                urlLink = urlLink + path?.search;
            }
            navigate(urlLink, { state: { from: pathListing } });
        } else {
            navigate('/');
        }
    }

    const logout = () => {
        removeLoginAccess();
        navigate('/login');
    };

    const login = () => {
        navigate('/login');
    };

    // CHATBOT
    useEffect(() => {
        const head = document.querySelector("head");
        const script = document.createElement("script");
        window.$crisp=[];window.CRISP_WEBSITE_ID="d55d5335-9b8c-4d17-805f-4d75bb076bf5";
        script.setAttribute("src", "https://client.crisp.chat/l.js");
        head.appendChild(script);

        var css = '#crisp-chatbox span.cc-imbb.cc-qfnu {  background-color: #404b7d82 !important; backdrop-filter: blur(5px); -webkit-backdrop-filter: blur(5px)!important; box-shadow: 3px 3px 3px 0 #00000042 !important }';
        
        var style = document.createElement('style');
        head.appendChild(style);

        style.type = 'text/css';
        if (style.styleSheet) {
            // This is required for IE8 and below.
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        return () => {
            head.removeChild(script);
        };
    }, []);

    return (
        _.includes(GO_BACK, location.pathname) ?
            <AppBar position='fixed' sx={{ maxWidth: 'inherit', width: '100%', right: 'auto' }}>
                <Toolbar>
                    <div style={{ border: '1px white solid', borderRadius: 10 }}>
                        <Tooltip title={t('general.back')} arrow>
                            <IconButton onClick={goBack} >
                                <ArrowBackIosNewIcon className="img-style" style={{ pointerEvents: "none" }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Toolbar>
            </AppBar>
            :
            _.includes(TRANSPARENT_PAGE, location.pathname) ?
                <AppBar position='fixed' sx={{ maxWidth: 'inherit', width: '100%', right: 'auto', backgroundColor: 'transparent' }}>
                    <Toolbar className='flex-sb-m w-full'>
                        {/* <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1 }}><IconButton onClick={goBack}><BiChevronLeft className="img-style" />{t('general.back')}</IconButton></Typography> */}
                        {
                            option?.showLogo &&
                            
                            <div>
                                {/* {getTitle()} */}
                                {/* <Typography component="div" sx={{ flexGrow: 1 }}>
                                
                                <Link component={RouterLink} to="/dashboard" underline='none' sx={{ display: 'flex', alignItems: 'center', fontWeight: 900, fontSize: 24, fontFamily: 'sans-serif', color: 'white' }}><PiStarFourFill className="img-style" style={{ marginRight: 5 }} /> XBoss</Link>
                                </Typography> */}
                                <Link component={RouterLink} to={accessToken ? "/" : "/login"} underline='none' state={{ from: storePaths(location) }} >
                                    <img src='/logo_text2.png' alt="logo" style={{ width: 130 }} />
                                </Link>
                            </div>
                        }
                        {/* {
                    accessToken ?
                        <Tooltip title={t('button.logout')} arrow><IconButton onClick={logout} ><FiLogOut className="img-style" /></IconButton></Tooltip>
                        :
                        <Tooltip title={t('button.login')} arrow><IconButton onClick={login} ><FiLogIn className="img-style" /></IconButton></Tooltip>

                } */}
                        <div>
                            <Tooltip title={t('general.language')} arrow><IconButton data-ref="language" onClick={handleClick} ><IoLanguage className="img-style" style={{ pointerEvents: "none" }} /></IconButton></Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                open={open === "language"}
                                onClose={handleClose}
                                onClick={handleClose}
                                disableScrollLock={true}
                                slotProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                {/* <MenuItem onClick={() => changeSystemLanguage('en')} >{t('general.en')}</MenuItem> */}
                                {/* <MenuItem onClick={() => changeSystemLanguage('cn')}  >{t('general.cn')}</MenuItem> */}
                                {
                                    _.map(systemLanguages, (_languages, index) => (
                                        <MenuItem key={index} onClick={() => changeSystemLanguage(_languages)} >{t(`general.${_languages}`)}</MenuItem>
                                    ))
                                }
                            </Menu>
                            {
                                accessToken &&
                                <IconButton data-ref="profile" onClick={handleClick} style={{ fontSize: 14, textTransform: "capitalize" }}><Avatar sx={{ width: 32, height: 32, marginRight: 1, pointerEvents: "none" }}>{name.charAt(0).toUpperCase()}</Avatar>{name}</IconButton>
                            }
                            {
                                accessToken &&
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open === "profile"}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    disableScrollLock={true}
                                    slotProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={() => navigate('/setting/profile')} >{t('title.profile')}</MenuItem>
                                    <MenuItem onClick={logout} >{t('button.logout')}</MenuItem>
                                </Menu>
                            }
                        </div>
                    </Toolbar>
                </AppBar>
                :
                <div>
                    <AppBar position='fixed' sx={{ maxWidth: '100%', width: 444, right: 'auto', backgroundColor: _.includes(DARK_COLOR, current_path) ? '#0D132C' : '' }}>
                        <Toolbar className='flex-sb-m w-full'>
                            {/* <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1 }}><IconButton onClick={goBack}><BiChevronLeft className="img-style" />{t('general.back')}</IconButton></Typography> */}
                            {
                                option?.showLogo &&

                                <div>
                                    {/* {getTitle()} */}
                                    {/* <Typography component="div" sx={{ flexGrow: 1 }}>
                            <Link component={RouterLink} to={accessToken ? "/" : "/login"} underline='none' sx={{ display: 'flex', alignItems: 'center', fontWeight: 900, fontSize: 24, fontFamily: 'sans-serif', color: 'white' }}><PiStarFourFill className="img-style" style={{ marginRight: 5 }} /> XBoss</Link>
                            </Typography> */}
                                    <Link component={RouterLink} to={accessToken ? "/" : "/login"} underline='none' state={{ from: storePaths(location) }} >
                                        <img src='/logo_text2.png' alt="logo" style={{ width: 130 }} />
                                    </Link>
                                </div>
                            }
                            {/* {
                accessToken ?
                    <Tooltip title={t('button.logout')} arrow><IconButton onClick={logout} ><FiLogOut className="img-style" /></IconButton></Tooltip>
                    :
                    <Tooltip title={t('button.login')} arrow><IconButton onClick={login} ><FiLogIn className="img-style" /></IconButton></Tooltip>

            } */}
                            <div>
                                <Tooltip title={t('general.language')} arrow><IconButton data-ref="language" onClick={handleClick} ><IoLanguage className="img-style" style={{ pointerEvents: "none" }} /></IconButton></Tooltip>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open === "language"}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    disableScrollLock={true}
                                    slotProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {/* <MenuItem onClick={() => changeSystemLanguage('en')} >{t('general.en')}</MenuItem>
                                    <MenuItem onClick={() => changeSystemLanguage('cn')}  >{t('general.cn')}</MenuItem> */}

                                    {
                                        _.map(systemLanguages, (_languages, index) => (
                                            <MenuItem key={index} onClick={() => changeSystemLanguage(_languages)} >{t(`general.${_languages}`)}</MenuItem>
                                        ))
                                    }
                                </Menu>
                                {
                                    accessToken &&
                                    <IconButton data-ref="profile" onClick={handleClick} style={{ fontSize: 14, textTransform: "capitalize" }}><Avatar sx={{ width: 32, height: 32, marginRight: 1, pointerEvents: "none" }}>{name.charAt(0).toUpperCase()}</Avatar>{name}</IconButton>
                                }
                                {
                                    accessToken &&
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={open === "profile"}
                                        onClose={handleClose}
                                        onClick={handleClose}
                                        disableScrollLock={true}
                                        slotProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                mt: 1.5,
                                                '&:before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                    zIndex: 0,
                                                },
                                            },
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        <MenuItem onClick={() => navigate('/setting/profile')} >{t('title.profile')}</MenuItem>
                                        <MenuItem onClick={logout} >{t('button.logout')}</MenuItem>
                                    </Menu>
                                }
                            </div>
                        </Toolbar>
                        <div className='p-lr-15 p-b-15 txt-right txt-white fs-14'>
                            <span>{t('general.serverTime')}: {serverTime}</span>
                        </div>
                        <div className='flex-sb-m p-lr-15'>
                            {option?.showBackBtn &&
                                <div className='p-b-15' style={{ zIndex: 3 }}>
                                    {option?.backUrl ?
                                        <Link underline='none' to={option?.backUrl} component={RouterLink} state={{ from: storePaths(location) }} className='flex-m' style={{color:'#fff'}}>
                                            <FiChevronLeft /><p className='p-l-10 fs-14'>{option?.backTitle}</p>
                                        </Link>
                                        : <div className='flex-m pointer txt-white' onClick={goBack}>
                                            <FiChevronLeft /><p className='p-l-10 fs-14'>{option?.backTitle}</p>
                                        </div>}

                                </div>
                            }

                            {option?.showTutorBtn &&
                                <Link className='p-b-15' component={RouterLink} to={option?.tutorUrl} underline='none' state={{ from: storePaths(location) }} >
                                    <div className='button-theme pointer txt-center' style={{ width: 100 }}>{t('title.tutorial')}</div>
                                </Link>
                            }
                        </div>
                    </AppBar>
                    {option?.showBackBtn &&
                        <div className='m-t-180'>
                        </div>
                    }
                </div>

                
    )
});

const useStyles = makeStyles((theme) => ({
}));

// TitleBar.defaultProps = {
//     showTitle: true,
// };

export default TitleBar;