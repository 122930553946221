import { Button, Grid, Typography } from '@mui/material/';
import { putUrl } from '@utils/ApiAction';
import { useQuery } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { removeLoginAccess } from '@utils/ApiAction';

import { MdCheckCircleOutline, MdOutlineCancel } from "react-icons/md";

export default function EmailVerification() {
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const [status, setStatus] = useState('pending');
    const navigate = useNavigate();
    const query = useQuery();
    const params = query.get('verify_url');

    useEffect(() => {
        setLoading(true);
        if (params) {
            let url = decodeURI(params);
            isMountedRef.current = true;
            putUrl(url).then(response => {
                setLoading(false);
                if (isMountedRef.current && response.status) {
                    setStatus('verified');
                    if (url.includes('update')) { // update email, force logout
                        removeLoginAccess();
                    }
                    addAlert('', response.message, 'success', '');
                } else {
                    addAlert('', response.message, 'error', '');
                    setStatus('failed');
                }
            }).catch(error => {
                setLoading(false);
                setStatus('failed')
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
            return () => isMountedRef.current = false;
        }
        // eslint-disable-next-line
    }, [addAlert, params]);


    return (
        <div className='app-header-margin'>
            <Grid px={3} py={2}>
                <Typography variant="h4" component="h1">{t("title.emailVerification")}</Typography>
            </Grid>
            {
                status === 'pending' ? null
                    :
                    <div className='txt-center bor30 transparent-box-dark w-full' style={{ margin: '0 auto' }}>
                        <div className=' txt-white p-lr-20 p-b-20'>
                            <div style={{ padding: '20px 0' }}>
                                {
                                    status === 'verified' ?
                                        <div style={{ width: 'fit-content', margin: '0 auto', textAlign: 'center' }}>
                                            <MdCheckCircleOutline style={{ fontSize: 100 }} />
                                            <Typography variant="h6" className="txt-white p-t-20">{t('emailVerification.status.' + status)}</Typography>
                                            {
                                                decodeURI(params).includes('update') ?
                                                    <Typography className="p-t-20 fs-14" style={{ color: '#B6B8C3', textAlign: 'justify' }}>{t('emailVerification.updatedEmail')}</Typography>
                                                    : null
                                            }
                                            <Button variant="contained" style={{ marginTop: 20 }} onClick={() => navigate("/dashboard")} >{t("general.back")}</Button>
                                        </div>
                                        :
                                        <div style={{ width: 'fit-content', margin: '0 auto', textAlign: 'center' }}>
                                            <MdOutlineCancel style={{ fontSize: 100 }} />
                                            <Typography variant="h6" className="txt-white p-t-20">{t('emailVerification.status.' + status)}</Typography>
                                            <p className='txt-white txt-center p-t-10' style={{ lineHeight: 'unset' }}>
                                                {t('emailVerification.failed_message')}
                                            </p>
                                            <Button variant="contained" style={{ marginTop: 20 }} onClick={() => navigate("/dashboard")} >{t("general.back")}</Button>

                                        </div>
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}