import React, { memo, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink, useNavigate, useParams  } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';

import { Button, MobileStepper, Box } from '@mui/material';

import { FiChevronLeft } from "react-icons/fi";

import TutorialHeader from '@layouts/TutorialHeader';



const Home = memo(() => {
    const theme = useTheme();
    const styles = useStyles();
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const { accessToken } = useSelector(state => state.general);

    const steps = [
        {
            label: t('tutorial.transferTitle-1'),
            imgPath: '/images/tutorial/transfer/step1.png',
            description: t('tutorial.transferDesc-1'),
        },
        {
            label: t('tutorial.transferTitle-2'),
            imgPath: '/images/tutorial/transfer/step2.png',
            description: t('tutorial.transferDesc-2'),
        },
        {
            label: t('tutorial.transferTitle-3'),
            imgPath: '/images/tutorial/transfer/step3.png',
            description: t('tutorial.transferDesc-3'),
        },
        {
            label: t('tutorial.transferTitle-4'),
            imgPath: `/images/tutorial/transfer/step4.png`,
            description: t('tutorial.transferDesc-4'),
        },
        {
            label: t('tutorial.transferTitle-5'),
            imgPath: `/images/tutorial/transfer/step5.png`,
            description: t('tutorial.transferDesc-5'),
        },
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = steps.length;

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };



    return (
        <div className="app-header-margin">
            {/* <Link component={RouterLink} to={} underline='none'>
                <div className='flex-m clwhite'>
                    <FiChevronLeft />
                    <p className='p-l-10 fs-13'>{t('button.back')}</p>
                </div>
            </Link> */}
            
            <div className='clwhite txt-center txt-title'>
                <p className='lh-12'>
                    <b>{t('tutorial.howToMake')}<br/>
                    {t('tutorial.transfer')}</b>
                </p>
            </div>
            
            <Box className="clwhite pos-relative " sx={{ maxWidth: 400, flexGrow: 1 }}>
                <div className='p-lr-20'>
                    <img src={steps[activeStep].imgPath} alt={t('title.tutorial')}/>
                </div>
                <div className='p-t-10 txt-center'>
                    <p className='p-b-15 fs-16 txt-title'><b>{steps[activeStep].label}</b></p>
                    <p>{steps[activeStep].description}</p>
                </div>

                <div style={{position:'relative'}}>
                <MobileStepper
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    style={{ background: '#ffffff1c', borderRadius:10, color: '#fff', position:'fixed', bottom:20, width:414, maxWidth:'calc(100vw - 30px)', backdropFilter: 'blur(8px)' }}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            style={{ width: 'unset', minWidth: 95, padding: 6 }}
                            disabled={activeStep === maxSteps - 1}
                        >
                            {t('button.nextStep')}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0} style={{ width: 'unset', padding: '3px 5px' }}>
                            {t('button.backStep')}
                        </Button>
                    }
                />
                </div>
            </Box>
        </div>
    )
});

const useStyles = makeStyles((theme) => ({

}));

export default Home;