import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const PasswordProfile = () => {
    const [showPassword, setShowPassword] = useState({
        currentPassword: false,
        password: false,
        passwordConfirmation: false,
    });
    const [state, setState] = useState({
        currentPassword: "",
        password: "",
        passwordConfirmation: "",
        sync: true,
    });
    const [inputErrors, setInputErrors] = useState({});

    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const styles = useStyles();
    const { t } = useTranslation();

    const handleChange = ({ target }) => {
        const { name, value } = target;

        setState({ ...state, [name]: value });
    }

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const updatePassword = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            current_password: state.currentPassword,
            password: state.password,
            password_confirmation: state.passwordConfirmation,
            sync: state.sync,
        }
        putUrl(`/user/password`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, currentPassword: "", password: "", passwordConfirmation: "" });
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div className='app-header-margin flex-col flex-m p-t-10' >
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" style={{ gap: 6 }} >
                <Grid item xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        label={t('user.currentPassword')}
                        placeholder={t('user.currentPassword')}
                        type={showPassword.currentPassword ? 'text' : 'password'}
                        value={state.currentPassword || ''}
                        onChange={handleChange}
                        name="currentPassword"
                        helperText={inputErrors && inputErrors.current_password ? inputErrors.current_password : ''}
                        error={inputErrors && inputErrors.current_password ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => handleClickShowPassword('currentPassword')}
                                    // onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword.currentPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        name="password"
                        label={t('user.newPassword')}
                        placeholder={t('user.newPassword')}
                        type={showPassword.password ? 'text' : 'password'}
                        value={state.password || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                        error={inputErrors && inputErrors.password ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => handleClickShowPassword('password')}
                                    // onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword.password ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        name="passwordConfirmation"
                        label={t('user.confirmPassword')}
                        placeholder={t('user.confirmPassword')}
                        type={showPassword.passwordConfirmation ? 'text' : 'password'}
                        value={state.passwordConfirmation || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                        error={inputErrors && inputErrors.password_confirmation ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => handleClickShowPassword('passwordConfirmation')}
                                    // onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword.passwordConfirmation ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox style={{ color: '#fff' }} onChange={() => setState({ ...state, sync: !state.sync})} checked={state.sync} />} label={<p className='txt-white'>{t('user.sync')}</p>} />
                </Grid>
                <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" >
                    <Button variant="contained" onClick={updatePassword} style={{ paddingTop: 10, paddingBottom: 10 }} >{t('button.save')}</Button>
                </Grid>
            </Grid>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    subText: {
        fontSize: '20px!important',
        color: theme.palette.gray.ltext,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },

}));

export default PasswordProfile