import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';

import { useTranslation } from 'react-i18next';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { useLocation, useNavigate } from 'react-router-dom';

// import CustomSelect from '@components/CustomSelect';

const Register = memo(() => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState({});
    const [searchParams] = useSearchParams();
    const [state, setState] = useState({
        username: '',
        name: '',
        email: '',
        mobile: '',
        password: '',
        passwordConfirmation: '',
        securityPassword: '',
        securityPasswordConfirmation: '',
        referralName: searchParams.get('referral') ? searchParams.get('referral') : '',
        // placementName: '',
        // placementPosition: 1,
    });
    const [success, setSuccess] = useState(false);
    let navigate = useNavigate();
    const [promo10U, setPromo10U] = useState(false);

    const [option, setOption] = useState({
        passwordShow: false,
        passwordConfirmationShow: false,
        securityPasswordShow: false,
        securityPasswordConfirmationShow: false
    });
    // const POSITION = [
    //     { 'code': 'left', 'value': 1, label: t('general.left') },
    //     { 'code': 'right', 'value': 2, label: t('general.right') }
    // ];

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const handlePasswordShow = (name) => {
        setOption({ ...option, [name]: !option[name] });
    }

    const handleSubmit = () => {
        setLoading(true);
        setInputErrors({});
        let params = {
            name: state.name,
            username: state.username,
            email: state.email,
            mobile: state.mobile,
            password: state.password,
            password_confirmation: state.passwordConfirmation,
            security_password: state.securityPassword,
            security_password_confirmation: state.securityPasswordConfirmation,
            referral_name: state.referralName,
            // placement_name: state.placementName,
            // placement_position: state.placementPosition
        }

        postUrl('/register', params).then(response => {
            setLoading(false);
            if (response.status) {
                setSuccess(true)
                addAlert('', response.message, 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let promo_date = moment(moment().format("YYYY-MM-DD HH:mm:ss")).isBetween('2024-02-10 00:00:00', '2024-02-12 23:59:59');
        if (promo_date) {
            setPromo10U(true);
        }
    }, [])

    return (
        success === true ?
            <div>
                <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center" >
                    <Grid item xs={12}   >
                        <div className='flex-col-c-m'>
                            <img src="images/background/Double_Star.png" style={{ marginTop: 60, height: '10vh' }} alt="logo" />
                            <Typography style={{ textAlign: 'center', fontSize: 30, fontWeight: 600 }}>{t('user.registerSuccess')}</Typography>
                            <Typography style={{ textAlign: 'center', fontSize: 16 }}>{t('user.registerSuccessSubline')}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" style={{ marginTop: 20 }} >
                        <Button variant="contained" onClick={() => navigate("/login")} fullWidth >{t('general.signIn')}</Button>
                    </Grid>
                </Grid>
            </div>
            :
            <form style={{ marginTop: 30, overflowY: 'hidden', marginBottom: 30 }}>
                <Helmet>
                    <meta name="description" content={promo10U ? "Sign Up Now for 10U Bonus" : t('general.previewLinkDesc')} />
                    <meta name="og:url" content={window.location.href} />
                    {/* <meta name="og:title" content={} /> */}
                    <meta property="og:type" content="website" />
                    <meta name="og:description" content={promo10U ? "Sign Up Now for 10U Bonus" : t('general.previewLinkDesc')} />
                    <meta property="og:image" content="images/general/angpau.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                    <Grid item xs={12}>
                        <div>
                            <Typography>{t('user.name')}</Typography>
                        </div>
                        <div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='name'
                                placeholder={t('user.name')}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                                value={state?.name}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Typography>{t('user.username')}</Typography>
                        </div>
                        <div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='username'
                                placeholder={t('user.username')}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                error={inputErrors && inputErrors.username ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                                value={state?.username}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Typography>{t('user.email')}</Typography>
                        </div>
                        <div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='email'
                                placeholder={t('user.email')}
                                onChange={handleChange}
                                required
                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                error={inputErrors && inputErrors.email ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                                value={state?.email}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Typography>{t('user.mobile')}</Typography>
                        </div>
                        <div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='mobile'
                                placeholder={t('user.mobile')}
                                onChange={handleChange}
                                required
                                helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                error={inputErrors && inputErrors.mobile ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                                value={state?.mobile}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Typography>{t('user.referralName')}</Typography>
                        </div>
                        <div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='referralName'
                                placeholder={t('user.referralName')}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.referral_name ? inputErrors.referral_name : ''}
                                error={inputErrors && inputErrors.referral_name ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                                value={state?.referralName}
                                disabled={searchParams.get("referral") ? true : false}
                            />
                        </div>
                    </Grid>
                    {/* <Grid item xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        name='placementName'
                        label={t('user.placementName')}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.placement_name ? inputErrors.placement_name : ''}
                        error={inputErrors && inputErrors.placement_name ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomSelect
                        label={t('user.placementPosition')}
                        listing={POSITION}
                        list_label='label'
                        list_value='value'
                        placeholder={t('general.selectOption', { option: t('user.position') })}
                        helperText={inputErrors && inputErrors.placement_position ? inputErrors.placement_position : ''}
                        error={inputErrors && inputErrors.placement_position ? true : false}
                        displayEmpty={true}
                        value={state.placementPosition}
                        onChange={handleChange}
                        name="placementPosition"
                    />
                </Grid> */}
                    <Grid item xs={12}>
                        <div>
                            <Typography>{t('user.password')}</Typography>
                        </div>
                        <div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='password'
                                placeholder={t('user.password')}
                                onChange={handleChange}
                                type={option?.passwordShow ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => handlePasswordShow('passwordShow')}>
                                                {option?.passwordShow ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true
                                }}
                                helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                                error={inputErrors && inputErrors.password ? true : false}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Typography>{t('user.confirmPassword')}</Typography>
                        </div>
                        <div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='passwordConfirmation'
                                placeholder={t('user.confirmPassword')}
                                onChange={handleChange}
                                type={option?.passwordConfirmationShow ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => handlePasswordShow('passwordConfirmationShow')}>
                                                {option?.passwordConfirmationShow ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true
                                }}
                                helperText={inputErrors && inputErrors.confirm_password ? inputErrors.confirm_password : ''}
                                error={inputErrors && inputErrors.confirm_password ? true : false}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    </Grid>
                    {/* SECURITY PASSWORD */}
                    <Grid item xs={12}>
                        <div>
                            <Typography>{t('user.securityPassword')}</Typography>
                        </div>
                        <div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='securityPassword'
                                placeholder={t('user.securityPassword')}
                                onChange={handleChange}
                                type={option?.securityPasswordShow ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => handlePasswordShow('securityPasswordShow')}>
                                                {option?.securityPasswordShow ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true
                                }}
                                helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                                error={inputErrors && inputErrors.security_password ? true : false}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Typography>{t('user.securityPasswordConfirm')}</Typography>
                        </div>
                        <div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='securityPasswordConfirmation'
                                placeholder={t('user.securityPasswordConfirm')}
                                onChange={handleChange}
                                type={option?.securityPasswordConfirmationShow ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => handlePasswordShow('securityPasswordConfirmationShow')}>
                                                {option?.securityPasswordConfirmationShow ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true
                                }}
                                helperText={inputErrors && inputErrors.security_password_confirmation ? inputErrors.security_password_confirmation : ''}
                                error={inputErrors && inputErrors.security_password_confirmation ? true : false}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" style={{ marginTop: 20 }} >
                        <Button type="button" variant="contained" onClick={handleSubmit} fullWidth >{t('button.submit')}</Button>
                    </Grid>
                </Grid>
            </form>
    )
});

export default Register;