import React, { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import { BottomNavigation, Paper, BottomNavigationAction, Fab, Dialog, Slide } from '@mui/material';
import { BiHomeAlt } from "react-icons/bi";
import { MdOutlineAccountTree, MdOutlineSettings, MdOutlineAccountBalanceWallet } from "react-icons/md";
import _ from 'lodash';
import { loginGame } from '@utils/ApiAction';
import VerifyEmailDialog from '@components/VerifyEmailDialog';

const MAIN_NAVIGATE = ['home', 'wallets', 'community', 'setting'];
const HIDE_BAR = ['/login', '/register', '/reset-password', '/packages/purchase', '/maintenance', '/forgot-password', '/email-verification'];


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BottomNavigationBar = memo(() => {
    const styles = useStyles();
    let location = useLocation();
    let navigate = useNavigate();
    const theme = useTheme();
    const { i18n, t } = useTranslation();
    const [value, setValue] = useState('home');
    const [hideBar, setHideBar] = useState(false);
    const { player_id, email_verified_at } = useSelector(state => state.user);
    const { accountSetting } = useSelector(state => state.general);

    const { accessToken } = useSelector(state => state.general);
    let path = location.pathname.split('/');

    useEffect(() => {
        setHideBar(false);
        let temp_value = _.intersection(MAIN_NAVIGATE, path);
        if (_.size(temp_value) > 0) {
            setValue(temp_value[0]);
        } else {
            if (path[1] === 'wallet') {
                setValue('wallets');
            } else if (path[1].includes('tutorial')) {
                setHideBar(true);
            }
            else {
                setValue('home');
            }
        }
    }, [path]);

    //Dialog
    const [open, setOpen] = React.useState(false);
    const handleClickOpenDialog = () => {
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setOpen(false);
    };



    return (
        _.includes(HIDE_BAR, location.pathname) || hideBar ?
            null
            :
            <div className='p-lr-15 pos-fixed w-full' style={{ maxWidth: '100%', top: 'auto', bottom: 20, zIndex: 1, width: 444 }}>
                <img src={`/images/bottom_navigation/iconbg.png`} alt="icon" className={styles.iconBgStyle} />
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    style={{ width: '100%', maxWidth: 'inherit', boxShadow: '0 2px 8px 0 #0007', height: 61, borderRadius: 10, justifyContent: 'space-around' }}
                    sx={{
                        "& .css-72wtuy-MuiButtonBase-root-MuiBottomNavigationAction-root": {
                            minWidth: 0
                        },
                        '& .Mui-selected': {
                            '& .MuiBottomNavigationAction-label': {
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                [theme.breakpoints.down('sm')]: {
                                    width: '55px',
                                },
                            },
                        },
                    }}
                >
                    <BottomNavigationAction
                        to={accessToken ? "/" : "/login"}
                        state={{ from: location }}
                        component={RouterLink}
                        label={t('title.home')}
                        icon={<BiHomeAlt className="img-style" />}
                        value="home"
                        className='txt-truncate'
                        style={{ textAlign: 'center', minWidth: 'unset', width: '15%' }}
                    />
                    <BottomNavigationAction
                        to={accessToken ? "/wallets/wallet" : "/login"}
                        state={{ from: location }}
                        label={t('title.wallet')}
                        component={RouterLink}
                        icon={<MdOutlineAccountBalanceWallet className="img-style" />}
                        value="wallets"
                        className='txt-truncate'
                        style={{ textAlign: 'center', minWidth: 'unset', width: '15%' }}
                    />
                    <div style={{ width: 92, display: 'flex', justifyContent: 'center' }}>
                        <div className={styles.backgroundStyle}>
                            {/* <img src={`/images/bottom_navigation/iconbg.png`} alt="icon" className={styles.iconBgStyle} /> */}
                            <img src={`/images/bottom_navigation/icon.png`} alt="icon" className={styles.iconStyle} />
                        </div>
                        {
                            (accountSetting?.email === true && !email_verified_at) ?
                                <Fab onClick={accessToken ? (player_id ? loginGame : handleClickOpenDialog) : () => navigate('/login')} disableTouchRipple style={{ background: 'linear-gradient(90deg, #55D3FF,#684EFF)', marginTop: '-25%', width: 74, height: 74, flexDirection: 'column', zIndex: 1 }}>
                                    <span style={{ color: "white", fontSize: 14, fontWeight: 600, fontFamily: 'system-ui', marginTop: 30, textTransform: "uppercase" }}>{t('title.game')}</span>
                                </Fab>
                                :
                                <Fab onClick={accessToken ? loginGame : () => navigate('/login')} disableTouchRipple style={{ background: 'linear-gradient(90deg, #55D3FF,#684EFF)', marginTop: '-25%', width: 74, height: 74, flexDirection: 'column', zIndex: 1 }}>
                                    <span style={{ color: "white", fontSize: 14, fontWeight: 600, fontFamily: 'system-ui', marginTop: 30, textTransform: "uppercase" }}>{t('title.game')}</span>
                                </Fab>
                        }
                    </div>
                    <BottomNavigationAction
                        to={accessToken ? "/community/sponsor" : "/login"}
                        state={{ from: location }}
                        component={RouterLink}
                        label={t('title.genealogy')}
                        icon={<MdOutlineAccountTree className="img-style" />}
                        value="community"
                        className='txt-truncate'
                        style={{ textAlign: 'center', minWidth: 'unset', width: '15%' }}
                    />
                    <BottomNavigationAction
                        to={accessToken ? "/setting" : "/login"}
                        state={{ from: location }}
                        component={RouterLink}
                        label={t('title.setting')}
                        icon={<MdOutlineSettings className="img-style" />}
                        value="setting"
                        className='txt-truncate'
                        style={{ textAlign: 'center', minWidth: 'unset', width: '15%' }}
                    />
                </BottomNavigation>

                <VerifyEmailDialog open={open} handleCloseDialog={handleCloseDialog} />
            </div>
    )
});


const useStyles = makeStyles((theme) => ({
    backgroundStyle: {
        width: 91,
        flexShrink: 0,
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundRepeat: "no-repeat",
        backgroundSize: 'contain',
        position: 'absolute',
        bottom: '67%',
        zIndex: 2,
        pointerEvents: 'none',
    },
    iconStyle: {
        width: 67,
        // height: 69,
        flexShrink: 0,
        mixBlendMode: 'lighten',
    },
    iconBgStyle: {
        top: '-180%',
        left: -35,
        right: 0,
        position: 'absolute',
        margin: 'auto',
        width: 122,
        pointerEvents: 'none',
        rotate: '-9deg'
    },
}));

export default BottomNavigationBar;