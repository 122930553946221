import { authFail, authSuccess } from '@actions';
import _ from 'lodash';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { reducePaths, storePaths } from '@utils/Tools';

// MUI
import { Button, Grid, IconButton, InputAdornment, TextField, Typography, Box, Link } from '@mui/material';
import { useTheme } from '@mui/styles';
import { FiEye, FiEyeOff } from "react-icons/fi";

export default function SignIn() {
    const { t } = useTranslation();
    const theme = useTheme();
    const [state, setState] = useState({
        username: '',
        password: '',
        showPassword: false,
        type: 'web',
    });
    const [inputErrors, setInputErrors] = useState();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    let navigate = useNavigate();
    let location = useLocation();

    let from = location.state?.from?.pathname || "/";

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const loginUser = (event) => {
        setLoading(true);
        event.preventDefault();
        postUrl('/login', state).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(authSuccess(response.data));
                if (_.size(location?.state) > 0) {
                    let pathListing= reducePaths(location);
                    let path = _.size(pathListing) > 0 ? _.last(pathListing) : '/';
                    let urlLink = path?.pathname;
                    if(path?.search !== ''){
                        urlLink = urlLink + path?.search;
                    }
                    navigate(urlLink, { state: { from: pathListing } });
                } else {
                    navigate('/');
                }
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            dispatch(authFail());
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }


    return (
        <Box component="form" onSubmit={loginUser} >
            <Grid container spacing={3} style={{ marginTop: 30 }}>
                <Grid item xs={12}>
                    <div>
                        <Typography>{t('user.username')}</Typography>
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={t('user.username')}
                            type="username"
                            autoComplete="username"
                            color='white'
                            value={state.username}
                            onChange={({ target }) => setState({ ...state, username: target.value })}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <Typography>{t('user.password')}</Typography>
                    </div>
                    <div style={{borderRadius:30}}>
                        <TextField
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {state.showPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            placeholder={t('user.password')}
                            type={state.showPassword ? 'text' : 'password'}
                            value={state.password}
                            onChange={({ target }) => setState({ ...state, password: target.value })}
                            helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                            error={inputErrors && inputErrors.password ? true : false}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Link component={RouterLink} to={`/forgot-password`} state={{ from: storePaths(location) }} style={{ textDecoration: 'none', marginBottom: '10px' }} color='inherit'><Typography>{t('login.forgotPassword')}</Typography> </Link>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 30, marginBottom: 115 }}>
                    <Button variant="contained" color="primary" size="large" type="submit" fullWidth ><Typography style={{fontSize:16, fontWeight:600}}>{t('general.signIn')}</Typography></Button>
                </Grid>
            </Grid>
        </Box>

    );
}


