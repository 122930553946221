import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

// MUI
import { TextField, Typography, InputAdornment, Box, Card, CardContent, Button, Link, Grid } from '@mui/material';

import { MdPerson } from "react-icons/md";

import { makeStyles, useTheme } from '@mui/styles';

export default function ForgetPassword() {
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();

    const [state, setState] = useState({
        email: ''
    });
    const [inputErrors, setInputErrors] = useState();
    const { addAlert, setLoading } = useNotificationLoading();

    const resetPassword = () => {
        setLoading(true);
        postUrl('/forgot-password', state).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Box className={styles.marginTop100} style={{ height: '100vh', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'  }} >
            <Typography className={styles.title}>{t('login.forgotPassword')}</Typography>
            {/* <center><img src="images/logos/logo_white_brand_full.png" style={{ height: 100, margin: '10px 0 20px' }} alt="logo" /></center> */}
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <Typography className={styles.desc} style={{ textAlign: 'start', marginTop: 10 }} gutterBottom>{t('forgotPassword.desc')}</Typography>
                </Grid>
                <Grid item xs={12} style={{marginTop:20}}>
                    <div>
                        <Typography>{t('user.email')}</Typography>
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            placeholder={t('user.email')}
                            fullWidth
                            type="email"
                            autoComplete="email"
                            color='primary'
                            value={state.email}
                            onChange={({ target }) => setState({ ...state, email: target.value })}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" size="large" onClick={resetPassword} fullWidth>{t('button.reset')}</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    marginTop100:{
        marginTop:100,
        padding:'0px 10% 0px 10%'
    },
    title :{
        fontSize:30,
        fontWeight:700,
        letterSpacing:'-0.3px',
        lineHeight:'130%'
    },
    desc :{
        fontSize:16,
        fontWeight:400,
        lineHeight:'125%'
    }
}));