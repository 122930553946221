import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Dialog, Slide } from '@mui/material';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VerifyEmailDialog = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();

    const verifyEmail = () => {
        setLoading(true);
        getUrl(`/user/send-email-verification-url/verify`).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
            } else {
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <React.Fragment>
                <Dialog
                    open={props.open}
                    onClose={props.handleCloseDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                >
                    <div className='p-all-24 txt-white'>
                        <div className='p-lr-5 p-b-10'>
                            <p className='fs-18 txt-center'><b>{t('home.verifyEmail')}</b></p>
                        </div>
                        <div>
                            <img src='/images/general/verify-email.png' alt={t('home.verifyEmail')} />
                        </div>
                        <div className='p-lr-5 fs-14 lh-12'>
                            <p className='p-b-10'>{t('home.verifyEmailDesc')}</p>
                        </div>
                        <div className='p-lr-5 p-t-15'>
                            <div className='w-full flex-r-m'>
                                <p className='fs-14 lh-12'>{t('home.verifyEmailAction')}</p>
                                <img src='/images/general/arrow.png' alt={t('home.verifyEmail')} style={{ width: 60 }} />
                            </div>
                            <div className='p-t-15'>
                                <Button variant='contained' onClick={verifyEmail}>{t('button.verifyEmail')}</Button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        </>
    )
}

export default VerifyEmailDialog;