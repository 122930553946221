import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';

let theme = createTheme({
    palette: {
        primary: {
            main: '#62BAEC',
            secondary: 'rgba(253, 237, 233, 1)'
        },
        secondary: {
            main: '#202E66',
            secondary: '#cf8f2e',
        },
        green: {
            main: green[500],
        },
        red: {
            main: red[500],
        },
        gray: {
            main: '#848484',
            secondary: '#b5b5b5',
            ltext: '#b7b7b7',
            dtext: '#606060',
            light: '#eaeaea',
            tableHeader: '#dbdbdb',
            tableHeaderText: '#606060',
        },
        indicator: {
            green: '#47ba80',
            red: '#f46464',
            yellow: '#f4c04e',
            disable: '#eaeaea',
        },
        button: {
            main: '#CDAC89',
            borderMain: '2px solid #CDAC89',
            darkRed: '#cd4949',
            yellow: '#ffcb58',
            green: '#47ba80'
        },
        bottomIconColor: '#ffffff',
        selectedBottomIconColor: 'linear-gradient(90deg, #55D3FF,#684EFF)',
        // backgroundColor: 'radial-gradient(65.21% 41.05% at 50% 50%, #061755 0%, #06071B 100%)',
        backgroundColor: 'rgba(18, 27, 62, 1)',
        white: '#ffffff',
        dialogContentBg: '#0D184F',
        inputIconColor: '#000000'
    },
    components: {
        // Name of the component
        MuiBottomNavigation: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // background: 'linear-gradient(180deg, #324499 8.95%, #06071B 91.05%)',
                    background: '#1a1f49',
                    // height:80,
                    // paddingBottom:15,
                },
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
                label: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth:'12vw',
                }
            },
        },
        
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        backgroundColor: '#141427',
                        background: '#141427',
                        color: '#7d7d7d'
                    },
                    '&:hover': {
                        color: '#000000 !important'
                    },
                    borderRadius: 8,
                    width:'100%',
                    textTransform: 'capitalize',
                    background: 'linear-gradient(90deg, #55D3FF,#684EFF)',
                    color: '#ffffff'
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {

                    // Default state of text input field font.
                    // '& .MuiInputBase-input.MuiInput-input': {
                    //     color: '#black',
                    // },

                    '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
                        // WebkitTextFillColor: '#black',
                        backgroundColor: "#ffffff",
                    },

                    // '& .MuiInputBase-root': {
                    //     color: '#black',
                    // },

                    // '& .MuiFormLabel-root.Mui-focused': {
                    //     color: '#black',
                    // },

                    '& .MuiFormLabel-root.Mui-disabled': {
                        color: '#ffffff',
                    },

                    // Default state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl::before': {
                        borderBottomColor: 'none',
                    },

                    // On hover state of underline.
                    // '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl:hover::before': {
                    //     color: '#black',
                    // },

                    // On focus state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.Mui-focused::after': {
                        borderBottomColor: 'none',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                    flexShrink: 0,
                    height: 55,
                    // color: '#black',
                    borderRadius: '15px !important',
                    padding:16,
                    marginTop:'20px !important',
                },
                input: {
                    '&:-webkit-autofill': {
                        WebkitTextFillColor: '#000000',
                        height: 7,
                        transition: 'background-color 5000s ease-in-out 0s',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    ":after": {
                        borderTop: "thin solid #ffffff"
                    },
                    ":before": {
                        borderTop: "thin solid #ffffff"
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#202E66",
                    color: '#ffffff',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    // borderTop: '2px solid #808080',
                    // borderBottom: '2px solid #808080',
                },
                indicator: {
                    backgroundColor: 'transparent',
                },
                scrollButtons: {
                    color: '#ffffff',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    // borderTop: '2px solid #808080',
                    // borderBottom: '2px solid #808080',
                    color:'#dfdfdf8f',
                    "&.Mui-selected": {
                        borderTop: 'none',
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#000000',
                    borderRadius:8,
                    padding:8,
                },
                arrow: {
                    color: '#000000',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                        display: 'none',
                    },
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    // backgroundColor: 'rgba(239, 75, 39, 1)',
                    background: 'linear-gradient(90deg, #55D3FF,#684EFF)'
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(18, 27, 62, 1)',
                    boxShadow: 'none'
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    width: 444,
                    maxWidth: '100%',
                    margin: '0 auto'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    color: '#222',
                    fontWeight: 'normal',
                    fontFamily: 'Inter'
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#202E66',
                    gap: 16,
                    borderRadius: 12,
                    alignItems: 'center',
                    width:320,
                    maxWidth:'80%'
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingBottom: 24,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingRight: 0,
                    paddingLeft: 0,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiLinearProgress:{
            styleOverrides:{
                root:{
                    backgroundColor:'#ffffff',
                    borderRadius:10,
                    height:10,
                },
                bar1Determinate:{
                    borderRadius:10,
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: (themeParam) => `
              & .MuiBottomNavigation-root &.Mui-selected {
                color: ${themeParam.palette.selectedBottomIconColor} !important;
              },
              & .MuiTab-root.Mui-selected{
                color: ${themeParam.palette.selectedBottomIconColor} !important;
                border-top: 2px solid ${themeParam.palette.selectedBottomIconColor};
                border-bottom: 2px solid ${themeParam.palette.selectedBottomIconColor};
              },
            `,
        },
    }
});

theme = responsiveFontSizes(theme);

export default theme;