import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, postUrl } from '@utils/ApiAction';
import { currencyFormat, truncate } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MdOutlineInfo, MdVisibility, MdVisibilityOff } from "react-icons/md";

export default function Withdrawal(props) {
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    let { id } = useParams();
    const [inputErrors, setInputErrors] = useState({});
    const { crypto_address } = useSelector(state => state.user);

    const [state, setState] = useState({
        wallet: [],
        rule: [],
        amount: 0,
        remark: "",
        securityPassword: ""
    });
    const [showPassword, setShowPassword] = useState({
        securityPassword: false,
    });

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    useEffect(() => {
        setLoading(true);
        getUrl(`/wallets/setting/withdrawal/${id}`).then(response => {
            if (response.status) {
                setState({ ...state, wallet: response.data.balance, rule: response.data.rule });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    const withdraw = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            wallet_id: id,
            amount: state.amount,
            remark: state.remark,
            security_password: state.securityPassword,
        }
        postUrl(`/wallets/withdrawal`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, username: '', amount: 0, remark: '', securityPassword: '' });
                addAlert('', t('success.withdrawSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.withdrawError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div className="app-header-margin">
            <div>
                    <div className='flex-sb-m p-b-40'>
                        <div className='p-tb-20 p-lr-10 txt-left' style={{ borderRadius: 8, background: 'linear-gradient(90deg, #55D3FF,#684EFF)', width:'30%' }}>
                            <p className='fs-13 txt-truncate' style={{ color: '#FAC7BC' }}>{t('withdrawal.balance')}</p>
                            <p className='fs-14 p-t-4' style={{ fontWeight: 600, color: '#ffffff' }}>{'$ ' + currencyFormat(state.wallet?.balance * 1 || 0, 2)}</p>
                        </div>
                        <div className='p-tb-20 p-lr-10 txt-left' style={{ borderRadius: 8, background: 'linear-gradient(90deg, #55D3FF,#684EFF)', width:'30%' }}>
                            <p className='fs-13 txt-truncate' style={{ color: '#FAC7BC' }}>{t('withdrawal.withdrawableBalance')}</p>
                            <p className='fs-14 p-t-4' style={{ fontWeight: 600, color: '#ffffff' }}>{'$ ' + currencyFormat((state.wallet?.balance - state.wallet?.locked_balance) * 1 || 0, 2)}</p>
                        </div>
                        <div className='p-tb-20 p-lr-10 txt-left' style={{ borderRadius: 8, background: 'linear-gradient(90deg, #55D3FF,#684EFF)', width:'30%' }}>
                            <p className='fs-13 txt-truncate' style={{ color: '#FAC7BC' }}>{t('withdrawal.lockedBalance')}</p>
                            <p className='fs-14 p-t-4' style={{ fontWeight: 600, color: '#ffffff' }}>{'$ ' + currencyFormat(state.wallet?.locked_balance * 1 || 0, 2)}</p>
                        </div>
                    </div>
                    <div className="p-b-25">
                        <TextField
                            variant="standard"
                            fullWidth
                            name="amount"
                            label={t('withdrawal.cashOutAmount')}
                            placeholder={'0'}
                            type="number"
                            value={state.amount || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                            error={inputErrors && inputErrors.amount ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true
                            }}
                        />
                        <p style={{ color: '#667085' }} className='txt-left p-t-8 fs-14'><MdOutlineInfo style={{ marginRight: 8 }} />{t('withdrawal.minimumWithdraw') + (state.rule?.details?.min_amount || 0)}</p>
                    </div>
                    <div className="p-b-25">
                        <TextField
                            disabled
                            variant="standard"
                            fullWidth
                            label={t('user.cryptoAddress')}
                            type="text"
                            value={crypto_address ? truncate(crypto_address, 20) : ''}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true
                            }}
                        />
                        {!crypto_address ? <p className='p-t-8 txt-left fs-14' style={{color:'#667085'}}>{t('error.noCryptoAddress')}</p> : null}
                    </div>
                    <div className="p-b-25">
                        <TextField
                            variant="standard"
                            fullWidth
                            name="remark"
                            label={t('wallet.remark')}
                            placeholder={t('wallet.remark')}
                            type="text"
                            value={state.remark || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                            error={inputErrors && inputErrors.remark ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true
                            }}
                        />
                    </div>
                    <div className="p-b-25">
                        <TextField
                            variant="standard"
                            fullWidth
                            name="securityPassword"
                            label={t('user.securityPassword')}
                            placeholder={t('user.securityPassword')}
                            type={showPassword.securityPassword ? 'text' : 'password'}
                            value={state.securityPassword || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                            error={inputErrors && inputErrors.security_password ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClickShowPassword('securityPassword')}
                                        >
                                            {showPassword.securityPassword ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div>
                        <Button variant="contained" onClick={withdraw} style={{ paddingTop: 10, paddingBottom: 10, marginTop: 10 }} fullWidth>{t('button.submit')}</Button>
                    </div>
                </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({

}));