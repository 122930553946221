import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';

// MUI
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

//Pages
import SigninDetail from './SignInDetail';
import Register from './Register';

export default function Login() {
    const { t } = useTranslation();
    const theme = useTheme();
    //TAB 
    const [tabValue, setTabValue] = useState(0);
    let navigate = useNavigate();
    const handleTabChange = (event, newValue) => {
        if (newValue === 0) {
            navigate("/login");
        } else if (newValue === 1) {
            navigate("/register");
        }
    };
    let location = useLocation();
    const [promo10U, setPromo10U] = useState(false);

    useEffect(() => {
        if (location.pathname === '/login') {
            setTabValue(0)
        } else if (location.pathname === '/register') {
            setTabValue(1)
        } else {
            setTabValue(0)
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box >
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let promo_date = moment(moment().format("YYYY-MM-DD HH:mm:ss")).isBetween('2024-02-10 00:00:00', '2024-02-12 23:59:59');
        if (promo_date) {
            setPromo10U(true);
        }
    }, [])

    return (
        <Box style={{ height: '100vh', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', position: 'relative' }}>
            <Helmet>
                <meta name="description" content={promo10U ? "Sign Up Now for 10U Bonus" : t('general.previewLinkDesc')} />
                <meta name="og:url" content={window.location.href} />
                {/* <meta name="og:title" content={} /> */}
                <meta property="og:type" content="website" />
                <meta name="og:description" content={promo10U ? "Sign Up Now for 10U Bonus" : t('general.previewLinkDesc')} />
                <meta property="og:image" content="images/general/angpau.png" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <img src="images/background/X.png" style={{ height: '50vh', position: 'absolute', top: '0', right: '0' }} alt="logo" />
            <Grid container spacing={2} style={{ zIndex: 2, marginTop: 60, padding: '0 10% 0 10%', overflow: 'auto', height: '100%', display: 'flex', justifyContent: 'flex-start', alignContent: 'flex-start' }}>
                <Grid item xs={12}>
                    <Typography style={{ fontWeight: 700, fontSize: 30, textAlign: 'center' }}> {process.env.REACT_APP_APP_NAME} </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Tabs value={tabValue} onChange={handleTabChange} style={{ backgroundColor: '#EDEEEF', borderRadius: 10, padding: '0px 4px 0px 4px', minHeight: 0, border: 0 }}
                        sx={{
                            "& button": { borderRadius: 2, margin: 0.5, color: '#101828', fontSize: 14, fontWeight: 600, minHeight: 0, height: 30 },
                            "& .Mui-selected": { backgroundColor: theme.palette.primary.main, color: 'white !important' },
                        }}
                    >
                        <Tab label={t('general.signIn')} {...a11yProps(0)} value={0} style={{ width: '50%' }} />
                        <Tab label={t('general.register')} {...a11yProps(1)} value={1} style={{ width: '50%' }} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0} >
                        <SigninDetail />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1} >
                        <Register />
                    </TabPanel>
                </Grid>
            </Grid>
        </Box>
    );
}
